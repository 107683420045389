<template>
	<div id="planting-plan-filtertabs">
		<button class="
		planting-plan-filtertabs__pill
		planting-plan-filtertabs__pill--disabled
		">Filter</button>
		<button
		@click="$emit('selection', 0)"
		:class="generatePillClass(0)"
		:disabled="createMode"
		>Pending</button>
		<button
		@click="$emit('selection', 1)"
		:class="generatePillClass(1)"
		:disabled="createMode"
		>Ongoing</button>
		<button
		@click="$emit('selection', 2)"
		:class="generatePillClass(2)"
		:disabled="createMode"
		>Completed</button>
	</div>
</template>

<script>
export default {
	name : "FilterTabs",
	props : {
		selectedIdx : {type : Number},
	},
	computed: {
		createMode()
		{
			return this.$store.state.newCycleCreation;
		}
	},
	methods : { 
		generatePillClass(index){
			let res = "planting-plan-filtertabs__pill";
			if (index == this.selectedIdx)
				res += " planting-plan-filtertabs__pill--selected"
			return res;
		}
	}
}
</script>

<style>
#planting-plan-filtertabs{
	display: flex;
	gap: 3px;
	width: 100%;
	margin-top: 19px;
	margin-bottom: 10px;
	overflow: auto;
}

.planting-plan-filtertabs__pill{
	border-radius: 12px;
	background-color: white;
	border: 1px solid #4D5061;
}

.planting-plan-filtertabs__pill:hover {
	background-color: #9E9E9E;
	color: #4D5061;
	border: 1px solid #9E9E9E;
}

.planting-plan-filtertabs__pill:active , .planting-plan-filtertabs__pill--selected {
	background-color: #32A3FF;
	color: white;
	border: 1px solid #32A3FF;
}

.planting-plan-filtertabs__pill--disabled{
	background-color: #D9D9D9;
}


</style>