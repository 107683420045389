<template>
	<div id="cycle-list-skelly">
		<div class="pulse"></div>
	</div>
</template>

<script>
export default {
	name : "CycleListSkeleton"
}
</script>

<style>
#cycle-list-skelly{
	height: 40vh;
}
#cycle-list-skelly div{
	height: 30px;
	width: 100%;
	background-color: grey;
}
</style>