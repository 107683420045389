<template>
	<div v-if="!selectedCycle">
		loading state...
	</div>
	<div
	v-else
	id="variant-actions"
	>
		<b-row>

			<!-- state selection -->
			<b-col
			cols="12"
			sm="6"
			>
				<v-select
				:disabled="cycleVariantMutationResult?.isLoading"
				:id="'variant-actions__state-select--' + selectedState?.toLowerCase()"
				:options="['Completed', 'Pending', 'Ongoing', 'Unassigned']"
				:components="{OpenIndicator}"
				placeholder="Select State"
				:clearable="false"
				v-model="selectedState"
				></v-select>
				
			</b-col>

			<!-- action btns-->
			<b-col
			cols="12"
			sm="6"
			>

				<div
					class="variant-actions__btngrp"
					>
						<!-- delete / cancel add btn -->
						<button
						:disabled="cycleVariantMutationResult?.isLoading"
						@click="toggleDelMode"
						:class="{
							'variant-actions__delete-btn': true,
							'variant-actions__delete-btn--active': deleteMode
							}">
							<i class="fas fa-trash"></i>
						</button>

						<!-- Add / save cycle btn -->
						<b-button
						pill
						variant="outline-info"
						size="sm"
						:disabled="cycleVariantMutationResult?.isLoading"
						>
							<div
							v-if="
							cycleVariantMutationResult?.isLoading
							"
							@click="handleSave"
							:style="{display : 'flex', alignItems: 'center', gap : '2px'}"
							>
								<b-spinner small label="Small Spinner"></b-spinner> Saving
							</div>
							<div
							v-else-if="
							pendingStateChanges.newState != '' ||
							pendingVariantChanges.remove.length > 0 || 
							pendingVariantChanges.update.length > 0
							"
							@click="handleSave"
							:style="{display : 'flex', alignItems: 'center', gap : '2px'}"
							>
								<img src='../../../assets/pencil-icon.svg' />
								Save changes
							</div>
							<div
							v-else
							:style="{display : 'flex', alignItems: 'center', gap : '2px'}"
							@click="() => {this.$emit('addVariant')}"
							>
								<img src='../../../assets/plus-icon.svg' /> Add a Variant
							</div>
						</b-button>
				</div>
			</b-col>
		</b-row>	
	</div>
</template>

<script>
import OpenIndicator from './OpenIndicator.vue'
import { cycleObserver, cycleVariantObserver } from '../../../query/plantingPlan/observers';

export default {
	name : "VariantActions",
	props : {
		pendingStateChanges : {type : Object},
		pendingVariantChanges : {type : Object},
		toggleDelMode : {type: Function},
		deleteMode : {type: Boolean},
	},
	computed :
	{
		selectedCycle()
		{
			return this.$store.state.selectedCycle;
		},
		farm()
		{
			return this.$store.state.farm;
		},
		newlyAddedVariants()
		{
			return this.$store.state.newlyAddedVariants;
		}
	},
	data(){
		return {
			OpenIndicator,
			selectedState : "",
			cycleVariantMutation: null,
			cycleVariantMutationResult: null,
			cycleVariantMutationUnsubscribe: () => {},
		}
	},
	methods : {
		
		/**`
		 * handleSaveUI -  executes changes in UI after change success
		 */
		handleSaveUI(){
			// handle adds
			// loop through data and find any status 201
			this.cycleVariantMutationResult?.data.forEach(element => {
				if (element.status == 201)
				{
					const newVar = element.data;
					this.selectedCycle.variants.push(newVar);
				}
				
			});
			
			// handle updates
			for (let index = 0; index < this.pendingVariantChanges.update.length; index++) {
				const update = this.pendingVariantChanges.update[index];

				// find index of variant in cycle
				const variantIdxInCycle = this.selectedCycle.variants.findIndex(e => e._id == update._id)

				if (variantIdxInCycle < 0) continue ;
				this.selectedCycle.variants[variantIdxInCycle] = update;
			}
			
			// handle removes
			for (let index = 0; index < this.pendingVariantChanges.remove.length; index++) {
				// find index of variant in cycle
				const newVariants = this.selectedCycle.variants.filter(e => {
					if (this.pendingVariantChanges.remove.findIndex(rm => rm._id == e._id) > -1)
						return false;
					return true;
				})

				this.selectedCycle.variants = newVariants;
			}

			// handle state change
			if (this.pendingStateChanges.newState != "")
			{
				// update frontend state
				this.selectedCycle.state = this.pendingStateChanges.newState
			}

			// reset pending changes
			// this.pendingStateChanges.newState = "";
			const newStateChanges = {...this.pendingStateChanges};
			newStateChanges.newState = "";
			this.$emit('pendingStateChangesChange', newStateChanges);

			const newVariantChanges = {...this.pendingVariantChanges};
			newVariantChanges.remove = [];
			newVariantChanges.update = [];
			newVariantChanges.add = [];
			this.$emit('pendingVariantChangesChange', newVariantChanges);
			this.$store.commit('setNewlyAddedVariants', []);

			this.$bvToast.toast(`Saved changes`, {
					title: 'Success',
					autoHideDelay: 3000,
					solid : true,
					variant: 'success'
				})
		},
		/**
		 * handleSave - handle save for add / save cycle btn 
		 */
		handleSave()
		{
			// if is saving, return
			if (this.isChangesSaving) return;

			this.handleSavePromsise()
			.then(() => {
				this.handleSaveUI()

				// invalidate query cache
				cycleObserver.observerQueryClient.removeQueries()
			})
			.catch((e) => {
				console.log("error ", e)
			})
		},
		/**
		 * handleSavePromsise - underlying promise for handle save for add / save cycle btn 
		 */
		handleSavePromsise(){
			return new Promise((resolve, reject) => {
				this.cycleVariantMutation.mutate(
				this.farm,
				{
					'varchanges': this.pendingVariantChanges,
					'statechanges' : this.pendingStateChanges,
				},
				reject,
				resolve
			)
			})
		},
	},
	/**
     * mounted - subscribes to cycleVariantObserver events and listen for fetch event completion
     * sets data for variants and provides user feedback of any error occurs
     */
	mounted()
	{
		const mutationInstance = cycleVariantObserver.getMutationInstance();
		this.cycleVariantMutation = mutationInstance;
		this.cycleVariantMutationUnsubscribe = mutationInstance.observer.subscribe((result) => {
			this.cycleVariantMutationResult = result;
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
					title: 'Error',
					autoHideDelay: 3000,
					solid : true,
					variant: 'danger'
					})
					return;
			}
		})
	},
	beforeDestroy()
	{
		this.cycleVariantMutationUnsubscribe();
	},
	watch : {
		selectedState()
		{
			if (this.selectedState != this.selectedCycle?.state)
			{
				const newStateChanges = {...this.pendingStateChanges};
				newStateChanges.newState = this.selectedState;
				this.$emit('pendingStateChangesChange', newStateChanges);
			}
			else
			{
				const newStateChanges = {...this.pendingStateChanges};
				newStateChanges.newState = "";
				this.$emit('pendingStateChangesChange', newStateChanges);
			}
		},
		selectedCycle : {
			handler(){
				this.selectedState = new String(this.selectedCycle?.state).toString()
			},
			immediate: true,
		},
	}
}
</script>

<style>
#variant-actions__state-select .vs__dropdown-toggle
{
	border: 0;
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	height: 45px;
	padding-right: 10px;
}

#variant-actions__state-select .vs__search
{
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */

	color: #666666;
}

#variant-actions__state-select--completed::before
{
	content: '';
	position: absolute;
	background: #39FF07;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
}

#variant-actions__state-select--completed ul {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

#variant-actions__state-select--completed li {
	padding-left : 14px;
}

#variant-actions__state-select--completed li:first-of-type {
	border-left: 4px solid #39FF07;
}

#variant-actions__state-select--completed li:nth-of-type(2) {
	border-left: 4px solid #FF0000;
}

#variant-actions__state-select--completed li:nth-of-type(3) {
	border-left: 4px solid #FF792E;
}

#variant-actions__state-select--completed li:nth-of-type(4) {
	border-left: 4px solid #C5C5C5;
}

#variant-actions__state-select--ongoing::before
{
	content: '';
	position: absolute;
	background: #FF792E;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
}

#variant-actions__state-select--ongoing ul {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

#variant-actions__state-select--ongoing li {
	padding-left : 14px;
}

#variant-actions__state-select--ongoing li:first-of-type {
	border-left: 4px solid #39FF07;
}

#variant-actions__state-select--ongoing li:nth-of-type(2) {
	border-left: 4px solid #FF0000;
}

#variant-actions__state-select--ongoing li:nth-of-type(3) {
	border-left: 4px solid #FF792E;
}

#variant-actions__state-select--ongoing li:nth-of-type(4) {
	border-left: 4px solid #C5C5C5;
}

#variant-actions__state-select--unassigned::before
{
	content: '';
	position: absolute;
	background: #C5C5C5;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
}

#variant-actions__state-select--unassigned ul {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

#variant-actions__state-select--unassigned li {
	padding-left : 14px;
}

#variant-actions__state-select--unassigned li:first-of-type {
	border-left: 4px solid #39FF07;
}

#variant-actions__state-select--unassigned li:nth-of-type(2) {
	border-left: 4px solid #FF0000;
}

#variant-actions__state-select--unassigned li:nth-of-type(3) {
	border-left: 4px solid #FF792E;
}

#variant-actions__state-select--unassigned li:nth-of-type(4) {
	border-left: 4px solid #C5C5C5;
}

#variant-actions__state-select--pending::before
{
	content: '';
	position: absolute;
	background: #FF0000;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
}

#variant-actions__state-select--pending ul {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

#variant-actions__state-select--pending li {
	padding-left : 14px;
}

#variant-actions__state-select--pending li:first-of-type {
	border-left: 4px solid #39FF07;
}

#variant-actions__state-select--pending li:nth-of-type(2) {
	border-left: 4px solid #FF0000;
}

#variant-actions__state-select--pending li:nth-of-type(3) {
	border-left: 4px solid #FF792E;
}

#variant-actions__state-select--pending li:nth-of-type(4) {
	border-left: 4px solid #C5C5C5;
}

.variant-actions__delete-btn{
	width: 31px;
	height: 31px;
	border-radius: 50%;
	background-color: #FF5656;
	border: none;
	color: #464646;
}

.variant-actions__delete-btn:hover, .variant-actions__delete-btn--active{
	background-color: transparent;
	border: 1px solid #FF5656;
	color: #FF5656;
}

.variant-actions__btngrp{
	display : flex;
	justify-content: center;
	gap : 5px
}

@media screen and (max-width: 426px){
.variant-actions__btngrp{
	padding-top: 10px;
	
}
}
</style>