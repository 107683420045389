<template>
	<div id="cycle-notes">

		<!--top bar-->
		<div class="cycle-notes__topbar">
			<b-row>
				
				<b-col
				cols="6"
				sm="6"
				:style="{
					textAlign : 'left'
				}"
				>
					Notes
				</b-col>

				<b-col
				cols="6"
				sm="6"
				:style="{
					textAlign : 'right'
				}">
					<button
					:style="{'background-color' : 'transparent', 'border' : '0'}"
					@click="toggleEditMode" v-if="!editMode">
						<!-- <i class="fas fa-pen"></i> -->
						<img src='../../../assets/pencil-icon.svg' />
					</button>
					
					<button
					:style="{'background-color' : 'transparent', 'border' : '0'}"
					@click="handleSave"
					:disabled="cycleNoteMutationResult.isLoading"
					v-else>
						<!-- <i class="fas fa-save"></i> -->
						{{ cycleNoteMutationResult.isLoading ? 'Saving...' : 'Save' }}
					</button>

				</b-col>

			</b-row>
		</div>

		<!--textarea-->
		<textarea
		class="cycle-notes__textarea"
		:disabled="!editMode || cycleNoteMutationResult.isLoading"
		v-model="message">
		</textarea>
		</div>
</template>

<script>
import { cycleNoteObserver } from '../../../query/plantingPlan/observers';
export default {
	name : "VariantNotes",
	props : {
		note : {type : Object},
		newCycleObject: {type : Object},
		newCycleCreation: {type: Boolean},
	},
	computed :
	{
		selectedCycle()
		{
			return this.$store.state.selectedCycle;
		},
		farm()
		{
			return this.$store.state.farm;
		},
	},
	data()
	{
		return {
			editMode : false, // edit mode flag
			message : this.newCycleCreation? this.newCycleObject.cycleNote.message : this.note?.message, // message in the textbox
			cycleNoteMutation: null, // cycle zone mutation object
			cycleNoteMutationResult: null, // cycle zone mutation result
			cycleNoteUnsubscribe: () => {}, // cycle zone mutation unsub function
		}
	},
	methods: {
		/**
		 * toggleEditMode - toggle the edit mode
		 */
		toggleEditMode(){
			this.editMode = !this.editMode
		},
		/**
		 * handleSaveUI - handle ui save changes 
		 */
		handleSaveUI()
		{
			// edit cycle note obj

			this.selectedCycle.notes = {...this.note , message : this.message}

			// revert saving state
			this.cycleNoteMutationResult.isLoading = false;

			// toggle edit mode
			this.toggleEditMode();

			this.$bvToast.toast(`Changes saved`, {
						title: 'Success',
						autoHideDelay: 3000,
						solid : true,
						variant: 'success'
					})
		},
		/**
		 * handleSaveUI - handle ui save backend 
		 */
		handleSave()
		{
			if (this.newCycleCreation)
			{
				const res = {...this.newCycleObject};
				res.cycleNote.message = this.message;
				this.$emit('newCycleObjectChange', res);
				this.toggleEditMode();
				return ;
				
			}

			const handleSavePromsise = () => {
				return new Promise((resolve, reject) => {
				this.cycleNoteMutation.mutate(
				this.farm,
				{...this.note , message : this.message},
				this.note._id,
				reject,
				resolve
			)
			})
			}

			handleSavePromsise()
			.then(() => {
				this.handleSaveUI()
			})
			.catch((e) => {
				console.log("error ", e)
			})
		},
		
	},
	/**
     * mounted - subscribes to cycleNoteObserver events and listen for fetch event completion
     * sets data for cycleNotes and provides user feedback of any error occurs
     */
	mounted()
	{
		const mutationInstance = cycleNoteObserver.getMutationInstance();
		this.cycleNoteMutation = mutationInstance;
		this.cycleNoteMutationUnsubscribe = mutationInstance.observer.subscribe((result) => {
			this.cycleNoteMutationResult = result;
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
						title: 'Error',
						autoHideDelay: 3000,
						solid : true,
						variant: 'danger'
					})
					return;
			}
		})
	},
	beforeDestroy()
	{
		this.cycleNoteMutationUnsubscribe();
	},
	watch : {
		note()
		{
			this.message = new String(this.note? this.note.message : "").toString()
		}
	}
}
</script>

<style>
#cycle-notes {
	padding: 19.75px 0;
}

.cycle-notes__topbar
{
	background: #FFD976;
	padding: 12px 7px 11px 12px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;

	text-transform: capitalize;

	color: #4A4A4A;
}

.cycle-notes__textarea{
	resize: none;
	outline: none;
	width: 100%;
	min-height: 181px;
	padding: 19px;
	background: #FFE7AB;
	border: 0;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 27px;

	text-transform: capitalize;

	color: #929292;

}
</style>