<template>
	<div class="pagination">
		<!-- backward button -->
		<button
		@click="handleBack"
		class="pagination__btn"
		>
			&lt;
		</button>

		<!-- page numbers -->
		<button
		v-if="displayedPages[0] != MAX_INT && displayedPages[0] > 1"
		@click="() => handleShift(BACKWARD)"
		class="pagination__btn"
		>
			...
		</button>

		<span v-for="(e, index) in displayedPages" :key="index">
			<button
			v-if="e != MAX_INT"
			@click="() => handleSel(e)"
			:class="{'pagination__btn': true, 'pagination__btn--active' : currentPage == e }"
			>
			{{ e }}
			</button>
		</span>

		<button
		v-if="displayedPages[displayedPages.length - 1] < totalPages"
		@click="() => handleShift(FORWARD)"
		class="pagination__btn"
		>
			...
		</button>

		<!-- fwd button -->
		<button
		class="pagination__btn"
		@click="handleFwd"
		>
			&gt;
		</button>

		<div>
			<!-- {{ displayedPages[0] }} -->
		</div>
	</div>
</template>

<script>
// emits page switch event
// takes current page as prop
// takes total elements as prop
// pagesize - prop

const MAX_INT = 2147483647;
const FORWARD = 0;
const BACKWARD = 1;
export default {
	name: "Pagination",
	props : {
		currentPage : {type: Number},
		totalElements: {type: Number},
		pageSize: {type: Number}
	},
	data() {
		return {
			displayedPages : [...Array(5)].fill(MAX_INT),
			FORWARD,
			BACKWARD,
			MAX_INT
		}
	},
	methods : {
		handleShift(direction){
			const currDisplayedPages = [...this.displayedPages];

			currDisplayedPages.forEach(
				(e, i) => (currDisplayedPages[i] = direction === FORWARD ? e + 1 : e - 1)
			);
			this.displayedPages = currDisplayedPages;
			if (direction == FORWARD) this.$emit('pageChange',this.displayedPages[ this.displayedPages.length - 1]);
			else this.$emit('pageChange',currDisplayedPages[0]);
		},
		handleBack()
		{
			if (this.currentPage == 1) return;
			if (this.currentPage == this.displayedPages[0]) return this.handleShift(BACKWARD);
			this.$emit('pageChange', this.currentPage - 1);
		},
		handleFwd()
		{
			if (this.currentPage == this.totalPages) return;
			if (this.currentPage == this.displayedPages[this.displayedPages.length - 1]) return this.handleShift(FORWARD);
			this.$emit('pageChange', this.currentPage + 1);
		},
		handleSel(sel)
		{
			this.$emit('pageChange', sel);
		}
	},
	computed : {
		totalPages()
		{
			return Math.ceil(this.totalElements / this.pageSize)
		},
	},
	watch: {
		// reevaluate displayed pages
		totalPages : {
			handler()
			{
				const newDisplayedPages = [...Array(5)].fill(MAX_INT);

				[...Array(5)].forEach((e, i) => {
					if (i < this.totalPages) newDisplayedPages[i] = i + 1;
				});

				newDisplayedPages.forEach((element, i) => {
					this.displayedPages[i] = element
				});
				this.displayedPages = newDisplayedPages;
			},
			// immediate : true,
		},
	}
}
</script>

<style>
.pagination__btn{
	border: 2px solid #D4D4D4;
	color: #D4D4D4;
	border-radius: 6px;
	outline: none;
	background: transparent;
	width: 29px;
	height: 29px;
	margin-left: 3px;
}

.pagination__btn--active{
	border: 2px solid #32A3FF;
	color: white;
	outline: none;
	background-color: #32A3FF;
}
</style>