import { default as axios } from "axios";

const fetchGrowAreas = async (farm) => {
	const resp = await axios.get(`/${farm}/growareas`, { })
	return resp;
}


const growAreasFunctions = {
	fetchGrowAreas
}

export default growAreasFunctions