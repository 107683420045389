import { default as axios } from "axios";

const deleteCycleVariant = async (farm, id) => {
	const resp = await axios.delete(`/cyclevariants/${id}`, {
		params : {farm},
	})
	return resp;
}

const updateCycleVariant = async (farm, id, body) => {
	const resp = await axios.put(`/cyclevariants/${id}`, body, {
		params : {farm},
	})
	return resp;
}

const addCycleVariant = async (farm, body) => {
	const resp = await axios.post(`/cyclevariants/`, body, {
		params : {farm},
	})
	return resp;
}

const cycleVariantFunctions = {
	deleteCycleVariant,
	updateCycleVariant,
	addCycleVariant
}

export default cycleVariantFunctions