<template>
	<div id="cycle-info">
		<!--Title-->
        <h4 class="planting-plan__view__title">Cycle Information</h4>

		<!-- cycle filters -->
		<FilterTabs @selection="handleStatusSelect" :selectedIdx="getFilterIdx()"/>

		<!--Cycles list-->
		<CycleListSkeleton
            v-if="!getCyclesObserverResult || getCyclesObserverResult.isLoading || !getZonesObserverResult || getZonesObserverResult.isLoading"
        />
        <div v-else-if="getCyclesObserverResult.isError">cycles Error</div>
		<CyclesList v-else :cycles="cycles" :zones="computedZones" />

        <!-- Actions -->
        <CycleActions
            :cycle="selectedCycle"
            :currentPage="currentPage + 1"
            :totalElements="totalElements"
            :pageSize="PAGE_SIZE"
            @pageChange="handlePageChange"
            @delCycle="handleDelCycle" />

		<!--planting zones-->
        <CycleZoneSkeleton
        v-if="
            !getZonesObserverResult || getZonesObserverResult.isLoading || 
            !getGrowAreasObserverResult || getGrowAreasObserverResult.isLoading
        "/>
        <div v-else-if="getZonesObserverResult.isError">zones Error</div>
        <CycleZoneDisplay
            v-else
            :zones="computedZones"
            @zoneUpdate="handleZoneUpdate"
            @zoneDelete="handleZoneDelete"
            :growAreas="computedGrowAreas"
        />        
	</div>
</template>

<script>
import CycleActions from '../common/CycleActions.vue'
import CycleListSkeleton from '../common/CycleListSkeleton.vue'
import CyclesList from '../common/CyclesList.vue'
import CycleZoneDisplay from '../common/CycleZoneDisplay.vue'
import CycleZoneSkeleton from '../common/CycleZoneSkeleton.vue'
import FilterTabs from '../common/FilterTabs.vue'
import { cycleObserver, cycleZoneObserver, growAreaObserver } from '../../../query/plantingPlan/observers'

export default {
	name : "CycleInfoView",
	data(){
		return {
			selectedStatusFilter : "",//status filter
            getCyclesObserver: null, // cycles observer tanstack
            getCyclesObserverResult: null, // cycles observer tanstack
            getCyclesUnsubscribe : () => {}, // observer unsubscribe function
            cycles : [], // cycles to display
            getZonesObserver: null, // zone observer tanstack
            getZonesObserverResult: null, // zone observer tanstack
            getZonesUnsubscribe : () => {}, // observer unsubscribe function
            zones : [], // zone to display
            getGrowAreasObserver: null, // zone observer tanstack
            getGrowAreasObserverResult: null, // zone observer tanstack
            getGrowAreasUnsubscribe : () => {}, // observer unsubscribe function
            growAreas : [], // zone to display
            currentPage: 0, // current page on pagination
            totalElements: 0, // total elements to display in list
            PAGE_SIZE: 5, // items per page for list

		}
	},
    computed : {
        selectedCycle()
        {
            return this.$store.state.selectedCycle;
        },
        farm()
        {
            return this.$store.state.farm;
        },
        /**
         * listParams - query parameters for fetching list data
         */
        listParams()
        {
            return {
                filterOn: 'state',
                filterBy: this.selectedStatusFilter,
                filterCompare : 'eq',
                limit: this.PAGE_SIZE,
                skip : this.currentPage * this.PAGE_SIZE,
                sortOn: '_id',
                sortBy: 'desc',
            }
        },
        /**
         * computedZones - combines existing zones with newly added zones
         */
        computedZones()
        {
            return [...this.zones, ...this.$store.state.newlyAddedZones]
        },  
        newlyAddedZones()
        {
            return this.$store.state.newlyAddedZones
        },
        computedGrowAreas()
        {
            // merge all growareas in all zones
            // BUG non-unique growarea names gets squashed here
            const res = [];

            for (const zone in this.growAreas) {
                this.growAreas[zone].forEach(growArea => {
                    res.push({...growArea, zone})
                });
            }
            return res;
        },
    },
    /**
     * mounted - subscribes to cycleObserver, cycleZonesObserver, growareaObserver events and listen for fetch event completion
     * sets data for cycles, zones and growareas and provides user feedback of any error occurs
     */
    mounted()
	{
		const observerInstance = cycleObserver.getObserverInstance(this.farm, {
                    limit: this.PAGE_SIZE,
                    skip: 0,
                    sortOn: '_id',
                    sortBy: 'desc',
                });
		this.getCyclesObserver = observerInstance
		this.getCyclesUnsubscribe = observerInstance.observer.subscribe((result) => {
            this.getCyclesObserverResult = result
            if (result.isError){
                this.$bvToast.toast(result.error?.response?.data.error, {
                        title: 'Error',
                        autoHideDelay: 3000,
                        solid : true,
                        variant: 'danger'
                    })
            }
            this.cycles = result?.data?.data.data
            this.totalElements = result?.data?.data.count

            // remove newly added cycles in every fetch
            this.$store.commit('setNewlyAddedCycles', []);
		})

        const ZoneObserverInstance = cycleZoneObserver.getObserverInstance(this.farm);
		this.getZonesObserver = ZoneObserverInstance
		this.getZonesUnsubscribe = ZoneObserverInstance.observer.subscribe((result) => {
            this.getZonesObserverResult = result
            if (result.isError){
                this.$bvToast.toast(result.error?.response?.data.error, {
                        title: 'Error',
                        autoHideDelay: 3000,
                        solid : true,
                        variant: 'danger'
                    })
            }
           
            this.zones = result?.data?.data.data
            // remove newly added cycle zones in every fetch
            this.$store.commit('setNewlyAddedZones', []);
		})
	
        const GrowAreaObserverInstance = growAreaObserver.getObserverInstance(this.farm);
		this.getGrowAreasObserver = GrowAreaObserverInstance
		this.getGrowAreasUnsubscribe = GrowAreaObserverInstance.observer.subscribe((result) => {
            this.getGrowAreasObserverResult = result
            if (result.isError){
                this.$bvToast.toast(result.error?.response?.data.error, {
                        title: 'Error',
                        autoHideDelay: 3000,
                        solid : true,
                        variant: 'danger'
                    })
            }
           
            this.growAreas = result?.data?.data
            // remove newly added cycle zones in every fetch
            // this.$store.commit('setNewlyAddedZones', []);
		})
	
    },
    /**
     * destroyed - unsubscribe to observers and cancel all ongoing queries
     */
    destroyed(){
		this.getCyclesUnsubscribe()
		cycleObserver.cancelQueries();
        cycleZoneObserver.cancelQueries();
        // TODO add cancel query here if GA is changable in futureos
	},
	methods: {
        /**
         * handleZoneUpdate - handles updating zone UI
         * @param {*} zone 
         */
        handleZoneUpdate(zone) {
            // searh for zone and update
            const zoneToUpdateIdx = this.computedZones.findIndex((e) => e._id == zone._id)
            this.computedZones[zoneToUpdateIdx] = zone;

            // reselect zone
            this.$store.commit('setSelectedZone', zone);
        },
        /**
         * handleZoneDelete - handles Zone Deletion UI
         * @param {*} id 
         */
        handleZoneDelete(id) {
            // find zone in zones
            const foundInZone = this.zones.findIndex((e) => e._id == id)
            if (foundInZone > -1)
            {
                this.zones.splice(foundInZone, 1);
                // reselect zone
                this.$store.commit('setSelectedZone', null);
                return;
            }

            // find zone is newly added zones
            const foundInNewlyAddedZone = this.newlyAddedZones.findIndex((e) => e._id == id)
            if (foundInNewlyAddedZone > -1)
            {
                const newNewlyAddedZones = this.newlyAddedZones.filter((e) => e._id != id)
                this.$store.commit('setNewlyAddedZones', newNewlyAddedZones)
                // reselect zone
                this.$store.commit('setSelectedZone', null);
                return;
            }
        },
        /**
         * handleStatusSelect - handles state management for status filter selection 
         * @param {*} idx 
         */
		handleStatusSelect(idx){
			const filterMap = {
				0: "Pending",
				1: "Ongoing",
				2: "Completed"
			}
			if (filterMap[idx] == this.selectedStatusFilter)
				this.selectedStatusFilter = "";
			else
				this.selectedStatusFilter = filterMap[idx];
            this.currentPage = 0;
            this.$store.commit('setSelectedCycle', null)
		},
        /**
         * getFilterIdx - gets numerical index for the current selected filter
         */
		getFilterIdx(){
			const filterMap = {
				"Pending" : 0,
				"Ongoing" : 1,
				"Completed" : 2
			}
			if (this.selectedStatusFilter == "") return -1;
			return filterMap[this.selectedStatusFilter];
		},
        /**
         * handlePageChange - state management for pagination page change event
         * @param {*} page 
         */
        handlePageChange(page){
            this.currentPage = page - 1
        },
        /**
         * handleDelCycle - handles cycle deletion at ui, also unselects everything
         * @param {*} id 
         */
        handleDelCycle(id){
            this.cycles = this.cycles.filter((e) => e._id != id)
            this.$store.commit('setSelectedCycle', null);
            this.$store.commit('setSelectedCycle', null);
            this.$store.commit('setSelectedZone', null);
            this.$store.commit('setNewCycleCreation', false);
            this.$store.commit('setNewCycleObject', {
                cycle: {
                    name: "",
                    state: "",
                    cycleZone : "",
                    germinationStartCalandarWeek : "", 
                    germinationEndCalandarWeek : "", 
                    propagationStartCalandarWeek : "",
                    propagationEndCalandarWeek : "", 
                    harvestStartCalandarWeek : "", 
                    harvestEndCalandarWeek : ""
                },
                cycleVariants : [],
                cycleNote : {message : ""},
            });
            this.$store.commit('setNewlyAddedCycles', []);
            this.$store.commit('setNewlyAddedVariants', []);

            // invalidate query cache
            cycleObserver.observerQueryClient.removeQueries()
        }
	},
    watch:{
        /**
         * Get new data when farm changes
         */
        farm(newfarm){
            this.$store.commit('setSelectedCycle', null);
            this.$store.commit('setSelectedCycle', null);
            this.$store.commit('setSelectedZone', null);
            this.$store.commit('setNewCycleCreation', false);
            this.$store.commit('setNewCycleObject', {
                cycle: {
                    name: "",
                    state: "",
                    cycleZone : "",
                    germinationStartCalandarWeek : "", 
                    germinationEndCalandarWeek : "", 
                    propagationStartCalandarWeek : "",
                    propagationEndCalandarWeek : "", 
                    harvestStartCalandarWeek : "", 
                    harvestEndCalandarWeek : ""
                },
                cycleVariants : [],
                cycleNote : {message : ""},
            });
            this.$store.commit('setNewlyAddedCycles', []);
            this.$store.commit('setNewlyAddedVariants', []);

            // invalidate query cache
            // cycleObserver.observerQueryClient.removeQueries()
            // cycleZoneObserver.observerQueryClient.removeQueries()
            // growAreaObserver.observerQueryClient.removeQueries()
            
            // reset parameters for observers
            this.getZonesObserver.setOptions(newfarm, this.listParams);

            this.getGrowAreasObserver.setOptions(newfarm, this.listParams);

            if (this.selectedStatusFilter == "")
            {
                this.getCyclesObserver.setOptions(this.farm, {
                    limit: this.listParams.limit,
                    skip: this.listParams.skip,
                    sortOn: '_id',
                    sortBy: 'desc',
                });
                return ;
            }
            this.getCyclesObserver.setOptions(newfarm, this.listParams);
        },

        /**
         * listParams - when it changes, request for a new set of data from backend via observer
         * options,
         * @param {*} newListParams 
         */
        listParams(newListParams){
            if (this.selectedStatusFilter == "")
            {
                this.getCyclesObserver.setOptions(this.farm, {
                    limit: newListParams.limit,
                    skip: newListParams.skip,
                    sortOn: '_id',
                    sortBy: 'desc',
                });
                return ;
            }
            this.getCyclesObserver.setOptions(this.farm, newListParams);
        }
    },
	components : {
		FilterTabs,
		CyclesList,
		CycleListSkeleton,
        CycleActions,
        CycleZoneDisplay,
        CycleZoneSkeleton,
	}
}
</script>