import { default as axios } from "axios";

const fetchCycleZones = async (farm) => {
	const resp = await axios.get(`/${farm}/cyclezones`, { })
	return resp;
}

const updateCycleZones = async (farm, id, body) => {
	const resp = await axios.put(`/${farm}/cyclezones/${id}`, body, { })
	return resp;
}

const addCycleZone = async (farm, body) => {
	const resp = await axios.post(`/${farm}/cyclezones`, body, { })
	return resp;
}

const deleteCycleZone = async (farm, id) => {
	const resp = await axios.delete(`/${farm}/cyclezones/${id}`, { })
	return resp;
}

const cycleZoneFunctions = {
	fetchCycleZones,
	updateCycleZones,
	addCycleZone,
	deleteCycleZone
}

export default cycleZoneFunctions
