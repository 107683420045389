<template>
	<div v-if="!selectedCycle && !newCycleCreation">
		No cycle selected
	</div>
	<div v-else>
		<!--title-->
		<h4 class="planting-plan__view__title">Cycle Dates</h4>

		<div class="planting-plan__growstages">	
			<div class="growstages__dates">
				<h5>Germination</h5>
				<!-- surplus input -->
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Surplus (%):"
				> 
					<b-form-input
						class="growstages__input"
						type="number"
						min="1"
						max="100"
						v-model="surplusPercentage"
					/>
				</b-form-group>
				<!-- germ cw input-->
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Start Date:"
				>
					<b-form-datepicker
						placeholder="dd-mm-yyyy"
						class="growstages__input"
						type="date"
						v-model="germinationStartCalandarWeek"
						@input="updateDates"
						locale="en-GB"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
					/> 
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Period (Days):"
				>
					<b-form-input
						class="growstages__input"
						type="number"
						min="1"
						v-model="germinationNDays"
						@input="updateDates"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="End Date:"
				>
					<b-form-datepicker
						disabled
						class="growstages__input"
						type="date"
						locale="en-GB"
						v-model="germinationEndCalandarWeek"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
					/> 
				</b-form-group>
			</div>
			
			<!--propagation cw input-->
			<div class="growstages__dates" :class="{'disabled': disablePropagation}">
				<b-form-checkbox class="d-inline" v-model="disablePropagation"></b-form-checkbox>
				<h5 class="d-inline">Propagation</h5>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Start Date:"
				>
					<b-form-datepicker
						placeholder="dd-mm-yyyy"
						class="growstages__input"
						type="date"
						locale="en-GB"
						:min="germinationEndCalandarWeek"
						v-model="propagationStartCalandarWeek"
						@input="updateDates"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
						:disabled="disablePropagation"
					/> 
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Period (Days):"
				>
					<b-form-input
						class="growstages__input"
						type="number"
						min="1"
						v-model="propagationNDays"
						@input="updateDates"
						:disabled="disablePropagation"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="End Date:"
				>
					<b-form-datepicker
						disabled
						class="growstages__input"
						type="date"
						locale="en-GB"
						v-model="propagationEndCalandarWeek"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
					/> 
				</b-form-group>
			</div>

			<!--harvest cw input-->
			<div class="growstages__dates">
				<h5>Harvest</h5>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Start Date:"
				>
					<b-form-datepicker
						placeholder="dd-mm-yyyy"
						class="growstages__input"
						type="date"
						locale="en-GB"
						:min="propagationEndCalandarWeek"
						v-model="harvestStartCalandarWeek"
						@input="updateDates"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
					/> 
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="Period (Days):"
				>
					<b-form-input
						class="growstages__input"
						type="number"
						min="1"
						v-model="harvestNDays"
						@input="updateDates"
					/>
				</b-form-group>
				<b-form-group
					label-cols-sm="3"
					label-cols-lg="2"
					label="End Date:"
				>
					<b-form-datepicker
						disabled
						class="growstages__input"
						type="date"
						locale="en-GB"
						v-model="harvestEndCalandarWeek"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
					/> 
				</b-form-group>
			</div>
		</div>

		<div class="planting-plan__growstages__footer">	
			<!-- save changes button-->
			<b-button
				@click="handleChange"
				variant="primary"
				v-if="!newCycleCreation"
				:disabled="
					pendingDateChanges.surplusPercentage == '' && 
					pendingDateChanges.germinationStartCalandarWeek == '' &&
					pendingDateChanges.germinationEndCalandarWeek == '' &&
					pendingDateChanges.propagationStartCalandarWeek == '' &&
					pendingDateChanges.propagationEndCalandarWeek == '' &&
					pendingDateChanges.harvestStartCalandarWeek == '' &&
					pendingDateChanges.harvestEndCalandarWeek == ''
				"
			>Save Changes</b-button>

			<b-button
				@click="handleNewCycleCreate"
				variant="primary"
				v-if="newCycleCreation"
				:disabled="
					pendingDateChanges.surplusPercentage == '' && 
					pendingDateChanges.germinationStartCalandarWeek == '' &&
					pendingDateChanges.germinationEndCalandarWeek == '' &&
					pendingDateChanges.propagationStartCalandarWeek == '' &&
					pendingDateChanges.propagationEndCalandarWeek == '' &&
					pendingDateChanges.harvestStartCalandarWeek == '' &&
					pendingDateChanges.harvestEndCalandarWeek == ''
				"
			>Create Cycle</b-button>
		</div>
	</div>
</template>

<script>
import { cycleObserver } from '../../../query/plantingPlan/observers';
import moment from 'moment';

export default {
	name : "DateInfoView",
	computed: {
		selectedCycle(){
			return this.$store.state.selectedCycle;
		},
		newCycleObject(){
			return this.$store.state.newCycleObject
		},
		newCycleCreation(){
			return this.$store.state.newCycleCreation
		},
		farm(){
            return this.$store.state.farm;
        }
	},
	methods : {
		updateDates(){
			if(this.germinationStartCalandarWeek != ""){
				let ndays = parseInt(this.germinationNDays);
				this.germinationEndCalandarWeek = moment(this.germinationStartCalandarWeek).add(ndays, "d").format("YYYY-MM-DD");
			}
			if(this.propagationStartCalandarWeek != ""){
				let ndays = parseInt(this.propagationNDays);
				this.propagationEndCalandarWeek = moment(this.propagationStartCalandarWeek).add(ndays, "d").format("YYYY-MM-DD");
			}
			if(this.harvestStartCalandarWeek != ""){
				let ndays = parseInt(this.harvestNDays);
				this.harvestEndCalandarWeek = moment(this.harvestStartCalandarWeek).add(ndays, "d").format("YYYY-MM-DD");
			}
		},
		/**
		 * handleCreateCycleBackend - sends create cycle to backend
		 * @param {*} newCycle 
		 */
		handleCreateCycleBackend(newCycle){
			return new Promise((resolve, reject) => {
				this.cycleMutation.mutate(
					this.farm,
					newCycle,
					-1,
					reject,
					resolve,
					true,
				)
			})
		},
		/**
		 * handleCreateCycleBackend - sends update cycle to backend
		 * @param {*} newCycle 
		 */
		handleMutateCycleBackend(newCycle){
			return new Promise((resolve, reject) => {
				this.cycleMutation.mutate(
					this.farm,
					newCycle,
					this.selectedCycle._id,
					reject,
					resolve,
					false,
				)
			})
		},
		handleChangeWithKey(key, value){
			this.pendingDateChanges[key] = value;
		},
		/**
		 * handleNewCycleCreate - creates new cycle object and calls backend post
		 */
		handleNewCycleCreate(){
			//update ui
			// paylaod cleanup
			const newCylcleObjCpy = {...this.newCycleObject};
			newCylcleObjCpy.cycleVariants = [...this.newCycleObject.cycleVariants];
			newCylcleObjCpy.cycleNote = {...this.newCycleObject.cycleNote};
			newCylcleObjCpy.cycle = {...this.newCycleObject.cycle};

			for (const key in this.pendingDateChanges) {
				// check for new cycle
				if (this.newCycleCreation)
				{
					// add pending date changes to new cycleobject
					newCylcleObjCpy.cycle[key] = this.pendingDateChanges[key];
					continue ;
				}
			}

			// remove variants id
			for (let index = 0; index < newCylcleObjCpy.cycleVariants.length; index++) {
				const variant = newCylcleObjCpy.cycleVariants[index];
				const varDup = {...variant};
				delete varDup._id
				newCylcleObjCpy.cycleVariants[index] = varDup
			}

			// add unassigned state
			newCylcleObjCpy.cycle.state = 'Unassigned'

			// save to backend
			this.handleCreateCycleBackend(newCylcleObjCpy)
			.then((data)=>{
				this.pendingDateChanges = {
					surplusPercentage : "",
					germinationStartCalandarWeek: "",
					germinationEndCalandarWeek: "",
					propagationStartCalandarWeek: "",
					propagationEndCalandarWeek: "",
					harvestStartCalandarWeek: "",
					harvestEndCalandarWeek: "",
				}

				// success, add created cycle to init cycle list into newly added cycles
				const newlyAddedCycles = this.$store.state.newlyAddedCycles
				newlyAddedCycles.push(data)
				this.$store.commit('setNewlyAddedCycles', newlyAddedCycles)
				
				// set selected cycle to new cycle
				this.$store.commit('setSelectedCycle', data)

				// set the creation cycles to null
				this.$store.commit('setNewCycleCreation', false);
				this.$store.commit('setNewCycleObject', {
					cycle: {
						name: "",
						state: "",
						cycleZone : "",
						germinationStartCalandarWeek : "", 
						germinationEndCalandarWeek : "", 
						propagationStartCalandarWeek : "",
						propagationEndCalandarWeek : "", 
						harvestStartCalandarWeek : "", 
						harvestEndCalandarWeek : ""
					},
					cycleVariants : [],
					cycleNote : {message : ""},
				});
				this.$bvToast.toast(`Created a new Cycle`, {
						title: 'Success',
						autoHideDelay: 3000,
						solid : true,
						variant: 'success'
					})

			// invalidate query cache
			cycleObserver.observerQueryClient.removeQueries()

			} )
			.catch((err) => {
				console.error(err);
			})

		},
		/**
		 * handleChange - handles date changes in both ui and backend
		 */
		handleChange(){
			const payload = {...this.pendingDateChanges,
				name: this.selectedCycle.name,
				state: this.selectedCycle.state,
				cycleZone: this.selectedCycle.cycleZone._id,
			};

			this.handleMutateCycleBackend(payload)
			.then(() => {
				//update ui
				for (const key in this.pendingDateChanges) {
					if (this.pendingDateChanges[key] == "") continue ;

					// check for new cycle
					if (this.newCycleCreation){
						this.newCycleObject.cycle[key] = this.pendingDateChanges[key];
						continue ;
					}
					this.selectedCycle[key] = this.pendingDateChanges[key];
				}

				this.$bvToast.toast(`Changed Cycle Dates`, {
						title: 'Success',
						autoHideDelay: 3000,
						solid : true,
						variant: 'success'
					})
			})
		},
		daysBetweenDates(a, b){
			let da = moment(a);
			let db = moment(b);
			return da.diff(db, 'days');
		},
	},
	data() {
		return {
			pendingDateChanges : {
				surplusPercentage : "",
				germinationStartCalandarWeek: "",
				germinationEndCalandarWeek: "",
				propagationStartCalandarWeek: "",
				propagationEndCalandarWeek: "",
				harvestStartCalandarWeek: "",
				harvestEndCalandarWeek: "",
			},
			disablePropagation: false,
			surplusPercentage: "",
			germinationNDays: 1,
			propagationNDays: 1,
			harvestNDays: 1,
			germinationStartCalandarWeek: "",
			germinationEndCalandarWeek: "",
			propagationStartCalandarWeek: "",
			propagationEndCalandarWeek: "",
			harvestStartCalandarWeek: "",
			harvestEndCalandarWeek: "",
			cycleMutation: null, // cycle mutation instance
			cycleMutationUnsubscribe: () => {}, // cycle mutation unsub fn
			cycleMutationResult: null, // cycle mutation result
		}
	},
	watch : {
		/**
		 * selectedCycle - everytime the seletedCycle changes, change the displayed date
		 * in according the cycles dates
		 */
		selectedCycle : {
			handler(){
				this.surplusPercentage = this.selectedCycle?.surplusPercentage
				this.germinationStartCalandarWeek = new Date(this.selectedCycle?.germinationStartCalandarWeek)
				this.germinationEndCalandarWeek = new Date(this.selectedCycle?.germinationEndCalandarWeek)
				this.germinationNDays = this.daysBetweenDates(this.germinationEndCalandarWeek, this.germinationStartCalandarWeek);
				this.propagationStartCalandarWeek = new Date(this.selectedCycle?.propagationStartCalandarWeek)
				this.propagationEndCalandarWeek = new Date(this.selectedCycle?.propagationEndCalandarWeek)
				this.propagationNDays = this.daysBetweenDates(this.propagationEndCalandarWeek, this.propagationStartCalandarWeek);
				this.harvestStartCalandarWeek = new Date(this.selectedCycle?.harvestStartCalandarWeek)
				this.harvestEndCalandarWeek = new Date(this.selectedCycle?.harvestEndCalandarWeek)
				this.harvestNDays = this.daysBetweenDates(this.harvestEndCalandarWeek, this.harvestStartCalandarWeek);
			},
			immediate : true,
		},
		surplusPercentage(){this.handleChangeWithKey('surplusPercentage', this.surplusPercentage)},
		germinationStartCalandarWeek(){this.handleChangeWithKey('germinationStartCalandarWeek', this.germinationStartCalandarWeek)},
		germinationEndCalandarWeek(){
			this.handleChangeWithKey('germinationEndCalandarWeek', this.germinationEndCalandarWeek);
			this.propagationStartCalandarWeek = this.germinationEndCalandarWeek
		},
		propagationEndCalandarWeek(){
			this.handleChangeWithKey('propagationEndCalandarWeek', this.propagationEndCalandarWeek)
			this.harvestStartCalandarWeek = this.propagationEndCalandarWeek
		},
		propagationStartCalandarWeek(){this.handleChangeWithKey('propagationStartCalandarWeek', this.propagationStartCalandarWeek)},
		harvestStartCalandarWeek(){this.handleChangeWithKey('harvestStartCalandarWeek', this.harvestStartCalandarWeek)},
		harvestEndCalandarWeek(){this.handleChangeWithKey('harvestEndCalandarWeek', this.harvestEndCalandarWeek)},
	},
	/**
     * mounted - subscribes to cycleObserver events and listen for fetch event completion
     * sets data for cycles and provides user feedback of any error occurs
     */
	mounted(){
		const mutationInstance = cycleObserver.getMutationInstance(this.farm)
		this.cycleMutation = mutationInstance
		this.cycleMutationUnsubscribe = mutationInstance.observer.subscribe((result)=>{
			this.cycleMutationResult = result
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
						title: 'Error',
						autoHideDelay: 3000,
						solid : true,
						variant: 'danger'
					})
					return;
			}
		})
	},
	beforeDestroy(){
		this.cycleMutationUnsubscribe();
	},
	components : {
	}
}
</script>

<style>
/* #date-info {	
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 26px;
	color: #4D5061;
	text-align: left;
} */

.planting-plan__growstages {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: top;
	text-align: left;
	font-size: 14px;
}

.growstages__dates {
	max-width: 600px;
	padding: 20px;
	margin: 2px 2px;
	border-radius: 10px;
	background-color: whitesmoke;
}

.growstages__dates.disabled {
	background-color: lightgray;
}

.growstages__input {
	min-width: 150px;
	max-width: 200px;
}

/* .date-info-surplus{
	padding: 11px 0;
}

.date-info__surplus-input {
	outline: none;
	width: 59px;
	height: 27px;

	border: 1px solid #4D5061;
	border-radius: 5px;
}

.date-info__date{
	padding: 41px 0 28px 0;
}

.date-info__date__group {
	background-color: #C7E6FF;
	border-radius: 7px;
	padding: 8px 16px;
}

.date-info__date__group .b-form-datepicker {
	outline: 0;
	background: transparent;
	border: 1px solid grey;
	font-size: 18px;
	max-width: 200px;
	display: inline-flex;
} */
</style>