<template>
	<div v-if="!selectedCycle && !newCycleCreation">
		No cycles selected
	</div>
	<div v-else id="variant-info">
		<!--title-->
		<!-- <AltTitle>Cycle Variant</AltTitle> -->
		<h4 class="planting-plan__view__title">Cycle Variants</h4>

		<!--cycle creation variant list-->
		<VariantListCycleCreation
			:variants="variants"
			v-if="newCycleCreation"
		/>
		

		<!--variant list-->
		<VariantList
		v-if="!newCycleCreation"
			:variants="combinedVarints"
			:deleteMode="varDelMode"
			:pendingVariantChanges="pendingVariantChanges"
			@pendingVariantChangesChange="handlePendingVariantChangesChange"
		/>

		<!--render variant creation field-->
		<!--select to create new variant-->
		<v-select
			v-if="addVariantToggle"
			id="variant-info__list__select"
			:options="variants"
			:components="{OpenIndicator}"
			placeholder="Select Variant"
			:clearable="false"
			v-model="currentSelectedVariant"
			:reduce="option => option.value"
		></v-select>

		<!--capacity display-->
		<!-- <div :style="{ margin: '10px 0' }" v-if="!newCycleCreation">
			Cycle Capacity: {{ this.selectedCycle.capacity }}
		</div> -->
		<CycleCapacityDisplay />
	

		<!-- variant actions -->
		<VariantActions
		v-if="!newCycleCreation"
			:pendingStateChanges="pendingStateChanges"
			:toggleDelMode="toggleDelMode"
			:deleteMode="varDelMode"
			:selectedCycle="selectedCycle"
			:pendingVariantChanges="pendingVariantChanges"
			@addVariant="handleAddVariant"
			@pendingVariantChangesChange="handlePendingVariantChangesChange"
			@pendingStateChangesChange="handlePendingStateChangesChange"
		/>

		<!-- notes -->
		<VariantNotes
			:note="selectedCycle?.notes"
			:newCycleObject="newCycleObject"
			:newCycleCreation="newCycleCreation"
			@newCycleObjectChange="handleNewCycleObjectChange"
		/>

	</div>
</template>

<script>
// import AltTitle from '../AltTitle.vue'
import OpenIndicator from '../common/OpenIndicator.vue';
import VariantActions from '../common/VariantActions.vue';
import VariantList from '../common/VariantList.vue';
import VariantListCycleCreation from '../common/VariantListCycleCreation.vue';
import VariantNotes from '../common/VariantNotes.vue';
import CycleCapacityDisplay from '../common/CycleCapacityDisplay.vue';

// dev dummy data
// const VARIANT_CHOICES = dummyVariants;

export default {
	name : "VariantInfoView",
	props: ['variants'],
	computed: {
		selectedCycle()
		{
			return this.$store.state.selectedCycle;
		},
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation
		},
		combinedVarints()
		{
			return [...this.selectedCycle?.variants]
		},
		newCycleObject()
		{
			return this.$store.state.newCycleObject
		},
	},
	data() {
		return {
			/**
			 * pendingVariantChanges - unsaved pending variant changes
			 */
			pendingVariantChanges: {
				add : [],
				remove : [],
				update : [],
				cycle : null,
			},
			/**
			 * pendingStateChanges - unsaved pending state changes
			 */
			pendingStateChanges : {
				newState : "",
				cycle : null,
			},
			// new variant name
			currentSelectedVariant : null,
			// add variant toggle
			addVariantToggle: false,
			OpenIndicator,
			varDelMode : false, // delete mode for variant list
		}
	},
	methods : {
		/**
		 * handleAddVariant - switches on the state for adding variant mode
		 */
		handleAddVariant()
		{
			this.addVariantToggle = true;
		},
		/**
		 * toggleDelMode - switches on the state for variant delete mode
		 */
		toggleDelMode(){
			this.varDelMode = !this.varDelMode;
		},
		/**
		 * handlePendingVariantChangesChange - handles changes on handlePendingVariantChanges
		 * from list items
		 * @param {*} e 
		 */
		handlePendingVariantChangesChange(e)
		{
			this.pendingVariantChanges = e;
		},
		/**
		 * handlePendingVariantChangesChange - handles changes on handlePendingVariantChanges
		 * from list items
		 * @param {*} e 
		 */
		handlePendingStateChangesChange(e)
		{
			this.pendingStateChanges = e;
		},
		/**
		 * handleNewCycleObjectChange - state update when newcycleobject is changed
		 * @param {*} e 
		 */
		handleNewCycleObjectChange(e)
		{
			this.$store.commit('setNewCycleObject', e)
		}
	},
	watch : {
		/**
		 * selectedCycle - everytime another cycle gets selected, reset the changes made
		 * on variant
		 */
		selectedCycle()
		{
			this.pendingVariantChanges = {
				add : [],
				remove : [],
				update : [],
				cycle : this.selectedCycle,
			}

			// state changes
			this.pendingStateChanges = {
				newState : "",
				cycle : this.selectedCycle,
			}
		},
		/**
		 * currentSelectedVariant - everytime another variant gets selected(created), push the 
		 * new variant to pending changes
		 */
		currentSelectedVariant()
		{
			if (!this.currentSelectedVariant) return;
			this.pendingVariantChanges.add.push({
				name: this.currentSelectedVariant,
				percentage: 0,
				forecastedYieldPerUnit: 0,
				cycle : this.selectedCycle._id,
				_id : this.combinedVarints.length
			})
			this.currentSelectedVariant = null;
			this.addVariantToggle = false;
		}
	},
	components : {
		// AltTitle,
		VariantList,
		VariantActions,
		VariantNotes,
		VariantListCycleCreation,
		CycleCapacityDisplay,
	}
}
</script>

<style>

/* #variant-info__list__select .vs__dropdown-toggle{
	background: #FFFFFF;
	border: 1px solid #32A3FF;
	border-radius: 8px;
	padding: 13px 16px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 26px;

	text-transform: capitalize;
	color: #444444;
} */

</style>