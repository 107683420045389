<template>
	<div>
		<b-modal
		id="modal-zone-delete"
		title="Delete cycle zone"
		:visible="showModal"
		@ok="handleOk"
		@hide="() => $emit('modalClose')"
		>
			delete zone {{ zoneToDel?.name }} ?
		</b-modal>
	</div>
</template>

<script>
import { cycleZoneObserver } from '../../../query/plantingPlan/observers';
export default {
	name: "CycleZoneDeleteModal",
	props :{
		showModal : {type: Boolean, default: false},
		growAreas : {type : Array},
		zones : {type : Array},
		zoneToDel : {type: Object},
	},
	computed : {
		validGAs()
		{
			const takenGAs = [];
			this.zones.forEach(zone => {
				zone.growareas.forEach(ga => {
					takenGAs.push(ga._id)
				});
			});
			const validGAs = this.growAreas.filter((ga) => 
				takenGAs.findIndex((taken) => taken == ga._id) < 0
			)
			return validGAs
		},
		farm()
		{
			return this.$store.state.farm
		},
		newlyAddedZones()
		{
			return this.$store.state.newlyAddedZones
		}
	},
	data(){
		return  {
			newZoneName : "",
			selectedGAs : [],
			zoneMutation: null, // zone mutation instance
			zoneMutationUnsubscribe: () => {}, // zone mutation unsub fn
			zoneMutationResult: null, // zone mutation result
		}
	},
	methods : {
		handleOk(bvModalEvent) {
			// Prevent modal from closing
			bvModalEvent.preventDefault()
			// Trigger submit handler
			this.handleSubmit()
		},
		handleSubmit()
		{
			// send request to backend
			const task = () => new Promise((resolve, reject) => {
                this.zoneMutation.mutate(
					this.farm,
					null,
					this.zoneToDel._id,
					'delete',
					reject,
					resolve,
				);
            })

			task()
			.then(() => {
				this.$bvToast.toast(`Deleted a Cycle Zone`, {
						title: 'Success',
						autoHideDelay: 3000,
						solid : true,
						variant: 'success'
					})

				// invalidate cache
				cycleZoneObserver.observerQueryClient.removeQueries()

				// invoke delete event
				this.$emit('zoneDeleted', this.zoneToDel._id)
				
				// emit close event
				this.$emit('modalClose')
			})
			.catch((e) => {
					console.error(e)
			})
			
			
		},
	},
	beforeDestroy()	
	{
		this.zoneMutationUnsubscribe()
	},
	mounted()
	{
		const mutationInstance = cycleZoneObserver.getMutationInstance(this.farm)
		this.zoneMutation = mutationInstance
		this.zoneMutationUnsubscribe = mutationInstance.observer.subscribe((result)=>{
			this.zoneMutationResult = result

			// handle errors
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
						title: 'Error',
						autoHideDelay: 3000,
						solid : true,
						variant: 'danger'
					})
				return ;
			}
			
		})
	},
}
</script>