<template>
	<div id="cycle-list-item">
		<div class="cycle-list-item__wrapper">
			<b-row no-gutters @click="handleSelect" :class="{
			'cycle-list-item-content' : true,
			'cycle-list-item-content--selected' : isSelected
			}
			">
				<!--grey cap-->
				<span
				v-if="isSelected"
				class="cycle-list-item-content__graycap"
				:style="{'background-color': cycle.state == 'Ongoing' ? '#FF792E' :
											cycle.state == 'Completed' ? '#39FF07' :
											cycle.state == 'Pending' ? 'red':
											'#7E7E7E'
											}"
				></span>

				<b-col cols="3" class="
				cycle-list-item__name
				">
					{{ cycle.name }}
				</b-col>

				<b-col cols="5"  class="
				cycle-list-item__info
				">
					<div :style="{paddingLeft : '10px'}">
						{{ formatDate(cycle.germinationStartCalandarWeek) }}
						&gt;
						{{ formatDate(cycle.harvestEndCalandarWeek) }}
					</div>
				</b-col>

				<b-col cols="2" class="
				cycle-list-item__stat
				">
					<div>
						{{ cycle.state }}
					</div>
				</b-col>

				<b-col cols="2" class="
				cycle-list-item__stat
				">
					<div
					:style="{
						width : '20px',
						height : '20px',
						borderRadius : '20px',
						backgroundColor : generateColorGZ(cycle.cycleZone?.name).color
					}"
					></div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import generateColorGrowingZone from "../scripts/generateColorForGrowingZone"


export default {
	name : "CycleListItem",
	props : {
		cycle : {type : Object},
		isSelected : {type : Boolean, default : false}
	},
	computed : {
		newCycleCreation(){
			return this.$store.state.newCycleCreation;
		}
	},
	methods :{
		formatDate(date){
			date = new Date(date)
			let year = date.getFullYear();

			let month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;

			let day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;

			return month + '/' + day + '/' + year;
		},
		generateColorGZ(zoneId){
			return generateColorGrowingZone(zoneId);
		},
		handleSelect()
		{
			if (this.newCycleCreation) return;
			this.$store.commit("setSelectedCycle", this.cycle)
		}
	},
}
</script>

<style>
.cycle-list-item__wrapper {
	position: relative;
	height: 43px;
	margin-bottom: 10px;
	overflow: hidden;
}

.cycle-list-item-content{
	padding: 0 7px;
	border: 1px solid #7E7E7E;
	border-radius: 10px;
	position: absolute;
	left: 0;
	width: 100%;
	transition:  0.3s;
}

.cycle-list-item-content--selected{
	background-color: #E3E3E3;
	left: 13px;
	border: 0;
	border-radius: 0 10px 10px 0;
}

/* .cycle-list-item-content--selected::before{
	content: ' ';
    display: inline-block;
    width: 13px;
    height: 100%;
	background-color: #7E7E7E;
	position: absolute;
	left: -13px;
	border-radius: 10px 0 0 10px;
} */

.cycle-list-item-content__graycap{
	content: ' ';
    display: inline-block;
    width: 13px;
    height: 100%;
	/* background-color: #7E7E7E; */
	position: absolute;
	left: -13px;
	border-radius: 10px 0 0 10px;
}


.cycle-list-item__name{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	padding :7px 0;
}

.cycle-list-item__info {
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	overflow: auto;
	border-left: 1px solid black;
}

.cycle-list-item__stat {
	display: flex;
	align-items: center;
	justify-content: center;
}

</style>