<template>
	<div id="cycle-zone-display__toptab__info-text"> 
		Total capacity : {{ capacity < 0 ? 0 : capacity }}
		<span
		:class="{
			'cycle-zone-display__toptab__info-text__capacity--pos' : capacityChange > 0,
			'cycle-zone-display__toptab__info-text__capacity--neg' : capacityChange < 0,
		}"
		>
			{{ capacityChange == 0 ? '' : capacityChange}}
		</span>
	</div>
</template>

<script>
export default {
	name : "CapacityDisplay",
	props : {
		pendingChanges :{type: Object},
		growAreas: {type: Array},
		selectedZone: {type: Object}
	},
	computed : {
		capacity()
		{
			let res;

			res = -1;

			if (!this.growAreas)
				return res;

			// return the sum of all growareas capacity if no selectedZone
			if (!this.selectedZone)
			{
				++res;
				this.growAreas.forEach(growArea => {
					res += growArea.capacity;
				});
				return res;
			}

			// if selectedZone, return the sum of all growareas of selectedzone
			if (this.selectedZone)
			{
				++res;
				this.selectedZone.growareas.forEach(growArea => {
					res += growArea.capacity;
				});
				return res;
			}
			return res;
		},
		capacityChange()
		{
			let res;

			res = 0;

			// if pending changes, return the change capacity
			if (this.pendingChanges?.add.length > 0 || this.pendingChanges?.remove.length > 0)
			{
				res = 0;
				// if (this.pendingChanges?.remove.length && this.pendingChanges?.remove.length > 0)
				// 	--res;

				this.pendingChanges.add.forEach(addition => {
					res += addition.capacity
				});
				this.pendingChanges.remove.forEach(subtraction => {
					res -= subtraction.capacity
				});
				return res;
			}

			return res;
		}
	},
}
</script>

<style>
.cycle-zone-display__toptab__info-text__capacity--neg{
	color: red;
}
.cycle-zone-display__toptab__info-text__capacity--pos{
	color: green;
}
.cycle-zone-display__toptab__info-text__capacity--pos::before{
	content: '+';
}
#cycle-zone-display__toptab__info-text{
	text-align: left;
	display: flex;
	align-items: center;
}
</style>