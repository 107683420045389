import { QueryObserver } from "@tanstack/vue-query";
import { cycleZoneFunctions } from '../functions'
import {queryClient} from '../../queryClient'

const observerQueryClient = queryClient

const getObserverInstance = (farm) => {
	const observer = new QueryObserver(observerQueryClient, {
		queryKey : ['getCyclesZones', farm],
		queryFn : () => cycleZoneFunctions.fetchCycleZones(farm),
		retry: false,
		staleTime: 5 * 60 * 1000,
	})


	const setOptions = (newFarm) => {
		observer.setOptions({
			queryKey : ['getCycles', newFarm],
			queryFn : () => cycleZoneFunctions.fetchCycleZones(newFarm),
			retry: false,
			staleTime: 5 * 60 * 1000,
		})
	}

	return {observer, setOptions}
}

const getMutationInstance = (farm) => {
	const observer = new QueryObserver(observerQueryClient, 
		{
			queryKey : ['putCyclesZones', farm],
			queryFn : () => {return []},
			retry: false,
		}
	)


	const mutate = (farm, body, id, mode, onError, onSuccess) => {
		// remove previous cache
		observer.remove();
		if (mode == 'update')
		{
			observer.setOptions(
				{
					queryKey : ['putCyclesZones', farm, id, body],
					queryFn : () => cycleZoneFunctions.updateCycleZones(farm, id, body),
					retry: false,
					onSuccess,
					onError,
					cacheTime: 0,
					select: (response) => response?.data
				}
			)
			return ;
		}
		if (mode == 'add')
		{
			observer.setOptions(
				{
					queryKey : ['addCycleZone', farm, body],
					queryFn : () => cycleZoneFunctions.addCycleZone(farm, body),
					retry: false,
					onSuccess,
					onError,
					cacheTime: 0,
					select: (response) => response?.data
				}
			)
			return ;
		}
		if (mode == 'delete')
		{
			observer.setOptions(
				{
					queryKey : ['deleteCycleZone', farm, id],
					queryFn : () => cycleZoneFunctions.deleteCycleZone(farm, id),
					retry: false,
					onSuccess,
					onError,
					cacheTime: 0,
					select: (response) => response?.data
				}
			)
			return ;
		}
	}

	return {observer, mutate}
}

const cancelQueries = () => {
	observerQueryClient.cancelQueries();
}

export default {
	cancelQueries,
	getObserverInstance,
	getMutationInstance,
	observerQueryClient,
}