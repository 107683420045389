<template>
	<div id="cycleZoneSkeleton" class="pulse">
		<!-- skeleton -->
	</div>
</template>

<script>
export default {
	name : "CycleZoneSkeleton"
}
</script>

<style>
#cycleZoneSkeleton {
	padding: 20px 0;
	background-color: grey;
	height: 34px;
}
</style>