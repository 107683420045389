var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cycle-list-item"}},[_c('div',{staticClass:"cycle-list-item__wrapper"},[_c('b-row',{class:{
		'cycle-list-item-content' : true,
		'cycle-list-item-content--selected' : _vm.isSelected
		},attrs:{"no-gutters":""},on:{"click":_vm.handleSelect}},[(_vm.isSelected)?_c('span',{staticClass:"cycle-list-item-content__graycap",style:({'background-color': _vm.cycle.state == 'Ongoing' ? '#FF792E' :
										_vm.cycle.state == 'Completed' ? '#39FF07' :
										_vm.cycle.state == 'Pending' ? 'red':
										'#7E7E7E'
										})}):_vm._e(),_c('b-col',{staticClass:"cycle-list-item__name",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.cycle.name)+" ")]),_c('b-col',{staticClass:"cycle-list-item__info",attrs:{"cols":"5"}},[_c('div',{style:({paddingLeft : '10px'})},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.cycle.germinationStartCalandarWeek))+" > "+_vm._s(_vm.formatDate(_vm.cycle.harvestEndCalandarWeek))+" ")])]),_c('b-col',{staticClass:"cycle-list-item__stat",attrs:{"cols":"2"}},[_c('div',[_vm._v(" "+_vm._s(_vm.cycle.state)+" ")])]),_c('b-col',{staticClass:"cycle-list-item__stat",attrs:{"cols":"2"}},[_c('div',{style:({
					width : '20px',
					height : '20px',
					borderRadius : '20px',
					backgroundColor : _vm.generateColorGZ(_vm.cycle.cycleZone?.name).color
				})})])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }