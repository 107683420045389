var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cycle-zone-display"}},[_c('div',{class:{
		'cycle-zone-display__save-dialog--hidden' : true,
		'cycle-zone-display__save-dialog' : _vm.pendingChanges.add.length > 0 || _vm.pendingChanges.remove.length > 0,

	}},[_c('b-row',{style:({minHeight : '53px'})},[_c('b-col',{style:({
			display : 'flex',
			justifyContent : 'center',
			alignItems: 'center',
			fontWeight: '400',
			fontSize: '16px',
			}),attrs:{"cols":"12","sm":"7"}},[_vm._v(" Changes found ")]),_c('b-col',{style:({
			display : 'flex',
			justifyContent : 'center',
			alignItems: 'center',
			gap : '10px'
			}),attrs:{"cols":"12","sm":"5"}},[(_vm.zoneMutationResult?.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_c('div',[_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":_vm.handleSaveZoneChanges}},[_vm._v(" Save "),_c('i',{staticClass:"fas fa-check-circle"})]),_c('b-button',{attrs:{"size":"sm","variant":"secondary"},on:{"click":_vm.handleDiscardZoneChanges}},[_vm._v(" Discard "),_c('i',{staticClass:"fas fa-trash"})])],1)],1)],1)],1),_c('div',{staticClass:"cycle-zone-display__toptab"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"cycle-zone-display__toptab__stackedicons scroll",attrs:{"cols":"5","id":"cycle-zone-display__toptab__stackedicons"}},_vm._l((_vm.zones),function(zone,index){return _c('div',{key:zone._id},[_c('div',{staticClass:"cycle-zone-display__toptab__stackedicons__icon__container"},[_c('div',{class:{
							'cycle-zone-display__toptab__stackedicons__icon' : true,
							'cycle-zone-display__toptab__stackedicons__icon--selected' : zone._id == _vm.selectedZone?._id 
						},style:({
							left : `-${_vm.isFocusedOnTab ? 0 : index * 20}px`,
							backgroundColor : _vm.generateColorForGrowingZone(zone.name).color
						}),on:{"click":()=>_vm.selectZone(zone)}},[_vm._v(" "+_vm._s(zone.name.charAt(0))+" ")])])])}),0),_c('b-col',{attrs:{"cols":"6"}},[_c('CapacityDisplay',{attrs:{"pendingChanges":_vm.pendingChanges,"growAreas":_vm.growAreas,"selectedZone":_vm.selectedZone}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v",style:({
							color : '#D9D9D9'
						})})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":() => _vm.showCycleCreateModal = true}},[_vm._v("Add new zone")]),(_vm.selectedZone)?_c('b-dropdown-item',{on:{"click":() => _vm.showCycleDeleteModal = true}},[_vm._v("Delete current zone")]):_vm._e()],1)],1)],1)],1),_c('CycleGAMap',{attrs:{"growAreas":_vm.growAreas,"zones":_vm.zones,"pendingChanges":_vm.pendingChanges},on:{"newAdd":(data) => this.handlePendingChangesChange('add', data),"newRemove":(data) => this.handlePendingChangesChange('remove', data)}}),_c('CycleZoneCreateModal',{attrs:{"showModal":_vm.showCycleCreateModal,"growAreas":_vm.growAreas,"zones":_vm.zones},on:{"modalClose":() => _vm.showCycleCreateModal = false}}),_c('CycleZoneDeleteModal',{attrs:{"showModal":_vm.showCycleDeleteModal,"zone-to-del":_vm.selectedZone},on:{"modalClose":() => _vm.showCycleDeleteModal = false,"zoneDeleted":(id) => _vm.$emit('zoneDelete', id)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }