<template>
	<div :style="{ margin: '10px 0' }" v-if="!newCycleCreation">
			Cycle Capacity: {{ this.selectedCycle.capacity }}
	</div>
	<!--Disabled, no backend support for now-->
	<!-- <div v-else>
		cycle creating zone zone is {{ newCycleObject.cycle.cycleZone }}
	</div> -->
</template>

<script>
export default {
	name: "CycleCapacityDisplay",
	computed : {
		selectedCycle()
		{
			return this.$store.state.selectedCycle;
		},
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation
		},
		newCycleObject()
		{
			return this.$store.state.newCycleObject
		}
	}
}
</script>