import { QueryObserver } from "@tanstack/vue-query";
import { cycleNoteFunctions } from '../functions'
import {queryClient} from '../../queryClient'

const observerQueryClient = queryClient

const getMutationInstance = (farm) => {
	const observer = new QueryObserver(observerQueryClient, 
		{
			queryKey : ['putCyclesNotes', farm],
			queryFn : () => {return []},
			retry: false,
		}
	)


	const mutate = (farm, body, id, onError, onSuccess) => {
		// remove previous cache
		observer.remove();

		observer.setOptions(
			{
				queryKey : ['putCyclesNotes', farm, id, body],
				queryFn : () => cycleNoteFunctions.updateCycleNotes(farm, id, body),
				retry: false,
				onSuccess,
				onError,
				cacheTime: 0,
				select: (response) => response?.data
			}
		)
	}

	return {observer, mutate}
}


const cancelQueries = () => {
	observerQueryClient.cancelQueries();
}

export default {
	cancelQueries,
	getMutationInstance,
	observerQueryClient,
}