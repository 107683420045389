
<template>
		<div id="cycle-list" class="scroll">
			<CycleListItem
				:isSelected="selectedCycle?._id == cycle._id"
				:cycle="cycle"
				v-for="(cycle, index) in combinedCycles" :key="index" />

			<!-- on cycle reation, render newcycleitem -->
			<div
				v-if="newCycleCreation"
				ref="newCreationItem"
			>
				<CreationCycleListItem :zones="zones"/>
			</div>

		</div>
</template>

<script>
import CreationCycleListItem from './CreationCycleListItem.vue';
import CycleListItem from './CycleListItem.vue';

export default {
	name: "CyclesList",
	props : {
		cycles : [],
		zones : [],
	},
	data(){
		return {
		}
	},
	computed : {
		selectedCycle(){
			return this.$store.state.selectedCycle;
		},
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation;
		},
		combinedCycles()
		{
			return [...this.cycles, ...this.$store.state.newlyAddedCycles]
		}
	},
	methods : {
		turnoffcreation()
		{
			this.$store.commit('setNewCycleCreation', false)
		}
	},
	watch : {
		// scrolls new item into view
		newCycleCreation()
		{
			if (this.newCycleCreation)
			{
				this.$nextTick(function(){
					this.$refs.newCreationItem.scrollIntoView();
				})
			}
		}
	},
	components : {
		CycleListItem,
		CreationCycleListItem,
	}
}
</script>

<style>
#cycle-list{
	overflow: auto;
	/* height: 40vh; */
}
</style>