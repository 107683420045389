<template>
	<div id="cycle-variant-list" class="scroll">
		<div v-if="variants?.length == 0 && !newCycleCreation">
			No variants found
		</div>

		
		<VariantListItem
		v-for="variant in filteredVariants"
		:key="variant._id"
		:variant="variant"
		:deleteMode="deleteMode"
		:pendingVariantChanges="pendingVariantChanges"
		@pendingVariantChangesChange="(e) => $emit('pendingVariantChangesChange', e)"
		/>

	</div>
</template>

<script>
import VariantListItem from './VariantListItem.vue';

export default {
	name: "CycleVariantList",
	props : {
		variants : {type : Array},
		deleteMode : {type: Boolean, default :false},
		pendingVariantChanges : {type: Object, default: function(){
			return  {
				add : [],
				remove : [],
				update : [],
				cycle : this.selectedCycle,
			}
		}}
	},
	computed: {
		selectedCycle()
		{
			return this.$store.state.selectedCycle;
		},
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation
		},
		/**
		 * filteredVariants - filter variant to only show updated or displayed ones
		 * remove pending deleted ones
		 */
		filteredVariants()
		{
			// render variant list item for each element in variants and skip for pending remove
			const res = this.variants?.filter(e => {
				// find e in remove changes
				return this.pendingVariantChanges.remove.findIndex(rm => rm._id == e._id) < 0
			})

			// render variant list item if new variant is pending to add
			this.pendingVariantChanges.add.forEach(variant => {
				const foundInDel = this.pendingVariantChanges.remove.findIndex(rm => rm._id == variant._id) > -1
				if (!foundInDel)
					res.push(variant)
			});
			

			// if cycle creation, push cycle creation variant
			return res;
		},
	},
	components: {
		VariantListItem
	}
}
</script>

<style>
#cycle-variant-list{
	padding: 26px 0;
}
</style>