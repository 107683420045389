<template>
	<div>
		<!-- Mobile display -->
		<div>
			<PlantingPlanContainerMobile :farm="farm" />
		</div>

	</div>
</template>

<script>
import PlantingPlanContainerMobile from '../components/plantingplan/PlantingPlanContainerMobile.vue'
import { queryClient } from '../query/queryClient';

export default {
	name : "PlantingPlan",
	components : {
		PlantingPlanContainerMobile,
	},
	props : {
		farm : {type : String}
	},
	data() {
		return {
			selectedCycle : null
		}
	},
	/**
	 * on mount - reset all selections 
	 */
	mounted(){
		this.$store.commit('setSelectedCycle', null);
		this.$store.commit('setSelectedZone', null);
		this.$store.commit('setNewCycleCreation', false);
		this.$store.commit('setNewCycleObject', {
			cycle: {
				name: "",
				state: "",
				cycleZone : "",
				germinationStartCalandarWeek : "", 
				germinationEndCalandarWeek : "", 
				propagationStartCalandarWeek : "",
				propagationEndCalandarWeek : "", 
				harvestStartCalandarWeek : "", 
				harvestEndCalandarWeek : ""
			},
			cycleVariants : [],
			cycleNote : {message : ""},
		});
		this.$store.commit('setNewlyAddedCycles', []);
		this.$store.commit('setNewlyAddedVariants', []);
	},
	watch : {
		/**
		 * farm - changes local farm store accordingly
		 */
		farm : {
			handler(){
				this.$store.commit('setFarm', this.farm);
			},
			immediate : true
		}
	},
	destroyed(){
		// invalidate query cache
		queryClient.removeQueries()
	}
}
</script>

