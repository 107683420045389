import { default as axios } from "axios";

const updateCycleNotes = async (farm, id, body) => {
	const resp = await axios.put(`/${farm}/cyclenotes/${id}`, body, { });
	return resp;
}

const cycleNoteFunctions = {
	updateCycleNotes
}

export default cycleNoteFunctions