var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.selectedCycle)?_c('div',[_vm._v(" loading state... ")]):_c('div',{attrs:{"id":"variant-actions"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"disabled":_vm.cycleVariantMutationResult?.isLoading,"id":'variant-actions__state-select--' + _vm.selectedState?.toLowerCase(),"options":['Completed', 'Pending', 'Ongoing', 'Unassigned'],"components":{OpenIndicator: _vm.OpenIndicator},"placeholder":"Select State","clearable":false},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"variant-actions__btngrp"},[_c('button',{class:{
						'variant-actions__delete-btn': true,
						'variant-actions__delete-btn--active': _vm.deleteMode
						},attrs:{"disabled":_vm.cycleVariantMutationResult?.isLoading},on:{"click":_vm.toggleDelMode}},[_c('i',{staticClass:"fas fa-trash"})]),_c('b-button',{attrs:{"pill":"","variant":"outline-info","size":"sm","disabled":_vm.cycleVariantMutationResult?.isLoading}},[(
						_vm.cycleVariantMutationResult?.isLoading
						)?_c('div',{style:({display : 'flex', alignItems: 'center', gap : '2px'}),on:{"click":_vm.handleSave}},[_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}),_vm._v(" Saving ")],1):(
						_vm.pendingStateChanges.newState != '' ||
						_vm.pendingVariantChanges.remove.length > 0 || 
						_vm.pendingVariantChanges.update.length > 0
						)?_c('div',{style:({display : 'flex', alignItems: 'center', gap : '2px'}),on:{"click":_vm.handleSave}},[_c('img',{attrs:{"src":require("../../../assets/pencil-icon.svg")}}),_vm._v(" Save changes ")]):_c('div',{style:({display : 'flex', alignItems: 'center', gap : '2px'}),on:{"click":() => {this.$emit('addVariant')}}},[_c('img',{attrs:{"src":require("../../../assets/plus-icon.svg")}}),_vm._v(" Add a Variant ")])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }