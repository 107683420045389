import { default as axios } from "axios";

const fetchCycles = async (farm, queryParams) => {
	const resp = await axios.get(`/${farm}/cycles`, {
		params: queryParams
	})
	return resp;
}

const updateCycle = async (farm, id, body) => {
	const resp = await axios.put(`/${farm}/cycles/${id}`, body, { })
	return resp;
}

const addCycle = async (farm, body) => {
	const resp = await axios.post(`/${farm}/cycles/`, body, { })
	return resp;
}

const delCycle = async (farm, id) => {
	const resp = await axios.delete(`/${farm}/cycles/${id}`, { })
	return resp;
}


const cycleFunctions = {
	fetchCycles,
	updateCycle,
	addCycle,
	delCycle,
}

export default cycleFunctions