import { QueryObserver } from "@tanstack/vue-query";
import { cycleFunctions, cycleVariantFunctions } from '../functions'
import {queryClient} from '../../queryClient'

const observerQueryClient = queryClient

// Hooks wont work because mixing data() and setup() in components would
// add unneeded complexity

const getMutationInstance = (farm) => {
	const observer = new QueryObserver(observerQueryClient, 
		{
			queryKey : ['putCycleVariants', farm],
			queryFn : () => {return []},
			retry: false,
		}
	)


	const mutate = (farm, body, onError, onSuccess) => {
		// remove previous cache
		observer.remove();

		// get updated add variants
		const updatedAddVars = body.varchanges?.update?.filter((e) => {
			const addVars = body.varchanges?.add
			const rmVars = body.varchanges?.remove

			const foundAddIdx = addVars.findIndex((variant) => variant._id == e._id)
			const foundRmIdx = rmVars.findIndex((variant) => variant._id == e._id);
			return foundAddIdx > -1 && foundRmIdx == -1;
		})

		// get variant updates without in delete or add
		const varaintUpdates =  body.varchanges?.update?.filter((e) => {
			const addVars = body.varchanges?.add
			const rmVars = body.varchanges?.remove
			const foundAddIdx = addVars.findIndex((variant) => variant._id == e._id)
			const foundRmIdx = rmVars.findIndex((variant) => variant._id == e._id);
			return foundAddIdx == -1 && foundRmIdx == -1;
		})

		// get variant delets
		const variantDeletes = body.varchanges?.remove.filter((e)=> typeof(e._id) == 'string')

		// create promise array
		const promiseArray = [];
		
		// add create actions
		updatedAddVars.forEach((e) => {
			promiseArray.push(cycleVariantFunctions.addCycleVariant(farm, {...e, _id: undefined, percentage : parseInt(e.percentage)}))
		})

		// add update actions
		varaintUpdates.forEach((e) => {
			promiseArray.push(cycleVariantFunctions.updateCycleVariant(farm, e._id, e))
		})

		// add delete actions
		variantDeletes.forEach(e => {
			promiseArray.push(cycleVariantFunctions.deleteCycleVariant(farm, e._id))
		});

		// add state change to cycle
		if (body.statechanges?.newState != "")
		{
			const newCycleBody = {...body.statechanges?.cycle};
			newCycleBody.state = body.statechanges?.newState;
			promiseArray.push(cycleFunctions.updateCycle(farm, body.statechanges?.cycle._id, newCycleBody))
		}

		const executePromise = async () => {
			const res = await Promise.all(promiseArray);
			return res;
		}

		observer.setOptions(
			{
				queryKey : ['updateVariants', farm, body],
				queryFn : () => executePromise(),
				retry: false,
				onSuccess,
				onError,
				cacheTime: 0,
			}
		)
	}

	return {observer, mutate}
}


const cancelQueries = () => {
	observerQueryClient.cancelQueries();
}

export default {
	cancelQueries,
	getMutationInstance,
	observerQueryClient,
}