import { QueryObserver } from "@tanstack/vue-query";
import { cycleFunctions } from '../functions'
import {queryClient} from '../../queryClient'

const observerQueryClient = queryClient

// Hooks wont work because mixing data() and setup() in components would
// add unneeded complexity

const getObserverInstance = (farm, queryParams) => {
	const observer = new QueryObserver(observerQueryClient, {
		queryKey : ['getCycles', farm, JSON.stringify(queryParams)],
		queryFn : () => cycleFunctions.fetchCycles(farm, queryParams),
		retry: false,
		staleTime: 5 * 60 * 1000,
	})


	const setOptions = (newFarm, newQueryParams) => {
		observer.setOptions({
			queryKey : ['getCycles', newFarm, JSON.stringify(newQueryParams)],
			queryFn : () => cycleFunctions.fetchCycles(newFarm, newQueryParams),
			retry: false,
			staleTime: 5 * 60 * 1000,
		})
	}

	return {observer, setOptions}
}

const getMutationInstance = (farm) => {
	const observer = new QueryObserver(observerQueryClient, 
		{
			queryKey : ['putCycle', farm],
			queryFn : () => {return []},
			retry: false,
		}
	)


	const mutate = (farm, body, id, onError, onSuccess, isCreate, isDelete) => {
		// remove previous cache
		observer.remove();

		if (isDelete)
		{
			observer.setOptions(
				{
					queryKey : ['delCycle', id, body],
					queryFn : () => cycleFunctions.delCycle(farm, id),
					retry: false,
					onSuccess,
					onError,
					cacheTime: 0,
					select: (response) => response?.data
				}
			)
			return;
		}

		if (isCreate)
		{
			observer.setOptions(
				{
					queryKey : ['postCycle', farm, body],
					queryFn : () => cycleFunctions.addCycle(farm, body),
					retry: false,
					onSuccess,
					onError,
					cacheTime: 0,
					select: (response) => response?.data
				}
			)
			return;
		}

		observer.setOptions(
			{
				queryKey : ['putCycle', farm, id, body],
				queryFn : () => cycleFunctions.updateCycle(farm, id, body),
				retry: false,
				onSuccess,
				onError,
				cacheTime: 0,
				select: (response) => response?.data
			}
		)
	}

	return {observer, mutate}
}


const cancelQueries = () => {
	observerQueryClient.cancelQueries();
}

export default {
	cancelQueries,
	getObserverInstance,
	getMutationInstance,
	observerQueryClient,
}