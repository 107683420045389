<template>
	<div id="planting-plan">
		<div class="view-btn-grp">
			<div ref="btn_pp1" class="view-btn" :class="{ active: activeView=='pp1'}" @click="goToView(0)"></div>
			<div ref="btn_pp2" class="view-btn" :class="{ active: activeView=='pp2'}" @click="goToView(1)"></div>
			<div ref="btn_pp3" class="view-btn" :class="{ active: activeView=='pp3'}" @click="goToView(2)"></div>
		</div>
		<div id="pp-container" v-on:scroll="handleScroll">
			<!-- cycle select -->
			<div ref="pp1" class="planting-plan__view">
				<CycleInfoView />
			</div>

			<!-- variant select -->
			<div ref="pp2" class="planting-plan__view">
				<VariantInfoView :variants="variantOptions" />
			</div>

			<!-- date select-->
			<div ref="pp3" class="planting-plan__view">
				<DateInfoView />
			</div>
		</div>
	</div>
</template>

<script>
import CycleInfoView from './cardviews/CycleInfoView.vue'
import DateInfoView from './cardviews/DateInfoView.vue'
import VariantInfoView from './cardviews/VariantInfoView.vue'
import axios from 'axios';

export default {
	name : "PlantingPlanContainerMobile",
	props: ['farm'],
	data() {
		return {
			initTouchX : 0, // initial touch state for sliding
			initCnt : 0,
			activeView: 'pp1',
			variantOptions: []
		}
	},
	computed : {
		mobileCycleSlideIdx(){
			return this.$store.state.mobileCycleSlideIdx
		},
	},
	watch: {
		farm: function(){
			this.getVariants();
		}
	},
	mounted(){
		this.getVariants();
	},
	methods : {
		/**
		 * handleMouseDown - stores the initial hold location
		 * @param {*} e event
		 */
		handleMouseDown(e){
			this.initTouchX = e.clientX;
		},
		/**
		 * handleMouseUp - compares the stored initial hold location and 
		 * the current release location and decides wether or not the action is
		 * a left slide or a right slide. Changes indices and cards accordingly.
		 * @param {*} e event
		 */
		handleMouseUp(e){
			let temp = e.target.parentNode;

			// ignore scroll elemtets
			while (
				!e.target.classList.contains('planting-plan__container__mobile__wrapper__slide') &&
				temp &&
				!temp.classList.contains('planting-plan__container__mobile__wrapper__slide')) {
				if (window.getComputedStyle(temp).overflowY == 'auto')
					return ;
				temp = temp.parentNode;
			}

			if (e.clientX - this.initTouchX > 50)
				this.prevCard();
			else if (this.initTouchX - e.clientX > 50)
				this.nextCard()
		},
		/**
		 * handleTouchStart - stores the initial hold location
		 * @param {*} e event
		 */
		handleTouchStart(e){
			this.initTouchX = e.changedTouches[0].clientX
		},
		/**
		 * handleTouchEnd - compares the stored initial hold location and 
		 * the current release location and decides wether or not the action is
		 * a left slide or a right slide. Changes indices and cards accordingly.
		 * @param {*} e event
		 */
		handleTouchEnd(e){
			let temp = e.target.parentNode;

			// ignore scroll elemtets
			while (
				!e.target.classList.contains('planting-plan__container__mobile__wrapper__slide') &&
				temp &&
				!temp.classList.contains('planting-plan__container__mobile__wrapper__slide')) {
				if (window.getComputedStyle(temp).overflowY == 'auto')
					return ;
				temp = temp.parentNode;
			}
			
			if (e.changedTouches[0].clientX - this.initTouchX > 50)
				this.prevCard();
			else if (this.initTouchX - e.changedTouches[0].clientX > 50)
				this.nextCard();
		},
		/**
		 * nextCard - changes the current moible cycle index to the next index.
		 */
		nextCard() {
			if (this.mobileCycleSlideIdx == 2) return 
			this.$store.commit('setMobileCycleSlideIdx', this.mobileCycleSlideIdx + 1)
		},
		/**
		 * prevCard - changes the current moible cycle index to the previous index.
		 */
		prevCard() {
			if (this.mobileCycleSlideIdx == 0) return;
			this.$store.commit('setMobileCycleSlideIdx', this.mobileCycleSlideIdx - 1)
		},
		/**
		 * goToView - go to a specfic view
		 */
		goToView(v){
			const pp_container = document.querySelector('#pp-container');
			const rect_pp_container = pp_container.getBoundingClientRect();
			const pp1 = this.$refs['pp1'].getBoundingClientRect();
			const diff = (v * pp1.width) + 30;
			console.log(`${rect_pp_container.top}, ${diff}`);
			pp_container.scrollTo({top: rect_pp_container.top, left: diff, behavior: 'smooth'})
		},
		handleScroll(){
			const pp_container = document.querySelector('#pp-container');
			const pp2 = this.$refs['pp2'].getBoundingClientRect();
			const pp3 = this.$refs['pp3'].getBoundingClientRect();
			// console.log(`${pp_container.scrollLeft}`);
			if(pp_container.scrollLeft < pp2.left){
				this.activeView = 'pp1';
			}
			else if(pp_container.scrollLeft >= pp3.left){
				this.activeView = 'pp3';
			}
			else{
				this.activeView = 'pp2';
			}
		},
		/**
		 * getCardClass - given a numerical index, generates a style class for that index
		 * @param {*} idxToSpotlight 
		 */
		getCardClass(idxToSpotlight) {
			const res = {
				'planting-plan__container__mobile__wrapper__slide' : true
			}
			if (idxToSpotlight > this.mobileCycleSlideIdx)
				res['planting-plan__container__mobile__wrapper__slide--hideright'] = true	
			if (idxToSpotlight < this.mobileCycleSlideIdx)
				res['planting-plan__container__mobile__wrapper__slide--hideleft'] = true	
			return res;
		},
		/**
		 * getVariants - obtain the list of variants for this farm
		 */
		getVariants(){
			axios.get(`/${this.farm}/variantManagement/variants`)
			.then((res) => {
				let avariants = res.data.active;
				avariants = avariants.map((v) => {
					v.label = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group} - ${v.source}`;
					v.value = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					return v;
				});
				this.variantOptions = avariants;
			})
			.catch((error) => {
				console.error(error);
				this.$emit('error', `Error retrieving variants`);
			})
        },
	},
	components : {
		CycleInfoView,
		VariantInfoView,
		DateInfoView,
	}
}
</script>

<style>

.view-btn-grp {
	z-index: 1;
}

.view-btn {
	height: 15px;
	width: 15px;
	margin-right: 6px;
	border-radius: 50%;
	border: 1.5px solid black;
	display: inline-block;
}

.view-btn.active {
	background-color: green;
}

#pp-container {
	position: relative;
	box-shadow: 3px 3px 2px 0px rgba(205, 205, 205, 1);
	border-radius: 6px;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	scrollbar-width: thin;
	white-space: nowrap;
}

.planting-plan__view {
	display: inline-block;
	padding: 10px 10px;
	margin: 10px 10px;
	border: 1px solid grey;
	transition: left 0.7s;
	width: 100%;
	max-width: 960px;
	vertical-align: top;
	scroll-snap-align: start;
	overflow-y: visible;
}

.planting-plan__view__title {
	text-align: left;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 21px;
	padding: 0 0 10px 0;
	border-bottom: 2px solid #F6F6F6;
}

</style>
