<template>
	<div id="creation-cycle-list-item">
		<div class="creation-cycle-list-item__wrapper">
			<b-row no-gutters :class="{
			'creation-cycle-list-item-content' : true
			}
			">
				<b-col cols="3" class="
				creation-cycle-list-item__name
				">
					<input
					@input="debounceInput"
					class="creation-cycle-list-item__name__input"
					/>
				</b-col>

				<b-col cols="7"  class="
				creation-cycle-list-item__info
				">
					
				</b-col>

				<b-col cols="2" class="
				creation-cycle-list-item__stat
				">
					<button
					@click="() => showModal = true"
					:style="{
						width : '20px',
						height : '20px',
						borderRadius : '20px',
						backgroundColor : selectedPlantingZone ? generateColorGZ(selectedPlantingZone.name).color : 'red',
						outline : '0',
						border : '0'
					}">
						
					
					
					</button>
				</b-col>
			</b-row>
		</div>

		<!-- planting zone modal -->
		<b-modal ok-only no-close-on-backdrop no-close-on-esc id="modal-1" v-model="showModal" title="Select planting zone">
			<v-select :clearable="false" v-model="selectedPlantingZone" label="name" :options="zones"></v-select>
		</b-modal>
	</div>
</template>

<script>
import generateColorGrowingZone from "../scripts/generateColorForGrowingZone"
import { debounce } from "debounce";

export default {
	name : "CreationCycleListItem",
	props : {
		zones : [],
	},
	computed: {
		newCycleObject()
		{
			return this.$store.state.newCycleObject
		},
		
	},
	data(){
		return {
			showModal : false,
			selectedPlantingZone : null,
		}
	},
	methods :{
		formatDate(date){
			date = new Date(date)
			let year = date.getFullYear();

			let month = (1 + date.getMonth()).toString();
			month = month.length > 1 ? month : '0' + month;

			let day = date.getDate().toString();
			day = day.length > 1 ? day : '0' + day;

			return month + '/' + day + '/' + year;
		},
		generateColorGZ(zoneId){
			return generateColorGrowingZone(zoneId);
		},
		debounceInput: debounce(function (e) {
			// set new cycle object
			this.newCycleObject.cycle.name = e.target.value;
		},1000)
	},
	watch:
	{
		selectedPlantingZone()
		{
			if (this.selectedPlantingZone)
			{
				this.newCycleObject.cycle.cycleZone = this.selectedPlantingZone._id
			}
		}
	}
}
</script>

<style>
.creation-cycle-list-item__wrapper {
	position: relative;
	height: 43px;
	margin-bottom: 10px;
	overflow: hidden;
}

.creation-cycle-list-item-content{
	padding: 0 7px;
	border: 0;
	border-radius: 0 10px 10px 0;
	position: absolute;
	left: 13px;
	width: 100%;
	background-color: #E3E3E3;
	transition:  0.3s;
}

.creation-cycle-list-item-content--selected{
	background-color: #E3E3E3;
	left: 13px;
	border: 0;
	border-radius: 0 10px 10px 0;
}

.creation-cycle-list-item-content::before{
	content: ' ';
    display: inline-block;
    width: 13px;
    height: 100%;
	background-color: #7E7E7E;
	position: absolute;
	left: -13px;
	border-radius: 10px 0 0 10px;
	/* padding: 0; */
}


.creation-cycle-list-item__name{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	padding :7px 0;
}

.creation-cycle-list-item__name__input{
	outline: 0;
	border-bottom: #7E7E7E;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	background-color: transparent;
	max-width: 80%;
}

.creation-cycle-list-item__info {
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	overflow: auto;
	border-left: 1px solid black;
}

.creation-cycle-list-item__stat {
	display: flex;
	align-items: center;
	justify-content: center;
}

#test ul{
	/* background-color: red; */
	z-index: 100;
	top: -10px;
	position: absolute;
}

</style>