<template>
	<div id="variant-list-item">
		<b-row>
			<!--variant name-->
			<b-col cols="12" sm="5">
				<div
				class="variant-list-item__name">
					{{ variant.name }}
				</div>
			</b-col>

			<!--percentage-->
			<b-col cols="12" sm="2">
				<div
				class="variant-list-item__percent"
				>
					<input
					type="number"
					class="variant-list-item__percent__input"
					v-model="percentage"
					min="1"
					max="99"
					:disabled="deleteMode"
					onkeydown="return event.keyCode !== 189"
					/>
				</div>
				
			</b-col>

			<!--yield/unit-->
			<b-col cols="12" sm="5">
				<div
				class="variant-list-item__yield"
				>
					Yield/Unit :
					<input
					type="number"
					class="variant-list-item__yield__input"
					v-model="forecastedYieldPerUnit"
					:disabled="deleteMode"
					/>
					g/unit
				</div>
			</b-col>

		</b-row>

		<!--remove button-->
		<div
		:class="{
			'variant-list-item__remove' : true,
			'variant-list-item__remove--hidden' : !deleteMode
		}
		"
		@click="() => markVariantforDelete(variant)"
		>
			Remove <img
			:style="{
				paddingLeft : '6px'
			}"
			src="../../../assets/cross-icon.svg" />
		</div>
	</div>
</template>

<script>
export default {
	name : "VariantListItem",
	props : {
		variant : {type : Object},
		deleteMode : {type: Boolean},
		pendingVariantChanges : {type: Object, default: function(){
			return  {
				add : [],
				remove : [],
				update : [],
				cycle : this.$store.selectedCycle,
			}
		}},
	},
	data(){
		return {
			percentage: this.variant.percentage,
			forecastedYieldPerUnit: this.variant.forecastedYieldPerUnit,
		}
	},
	methods : {
		/**
		 * markVariantforDelete - pushes current variant as deletion to pendingvariantchanges
		 * @param {*} v 
		 */
		markVariantforDelete(v)
		{
			const res = {...this.pendingVariantChanges};
			res.remove.push(v);
			this.$emit('pendingVariantChangesChange', res);
		}
	},
	watch :{
		// TODO add debouncing?
		/**
		 * percentage - everytime percentage changes, find variant in pending changes and update that value
		 */
		percentage()
		{
			// find variant in update
			const foundInUpdate = this.pendingVariantChanges.update.findIndex(e => e._id == this.variant._id);

			// if found, cehck diff and update
			if (foundInUpdate >= 0)
			{
				// this.pendingVariantChanges.update[foundInUpdate].percentage = this.percentage
				const res = {...this.pendingVariantChanges};
				res.update[foundInUpdate].percentage = this.percentage
				this.$emit('pendingVariantChangesChange', res);
				return ;
			}

			// if not, create new push to update array with new values
			const newVariant = {...this.variant};
			newVariant.percentage = this.percentage;
			// this.pendingVariantChanges.update.push(newVariant);
			const res = {...this.pendingVariantChanges};
			res.update.push(newVariant);
			this.$emit('pendingVariantChangesChange', res);
		},
		/**
		 * forecastedYieldPerUnit - everytime forecastedYieldPerUnit changes, find variant in pending changes and update that value
		 */
		forecastedYieldPerUnit()
		{
			// find variant in update
			const foundInUpdate = this.pendingVariantChanges.update.findIndex(e => e._id == this.variant._id);

			// if found, update
			if (foundInUpdate >= 0)
			{
				// this.pendingVariantChanges.update[foundInUpdate].forecastedYieldPerUnit = this.forecastedYieldPerUnit
				const res = {...this.pendingVariantChanges};
				res.update[foundInUpdate].forecastedYieldPerUnit = this.forecastedYieldPerUnit
				this.$emit('pendingVariantChangesChange', res);
				return ;
			}

			// if not, create new push to update array with new values
			const newVariant = {...this.variant};
			newVariant.forecastedYieldPerUnit = this.forecastedYieldPerUnit;
			// this.pendingVariantChanges.update.push(newVariant);
			const res = {...this.pendingVariantChanges};
			res.update.push(newVariant);
			this.$emit('pendingVariantChangesChange', res);
		}
	}
}
</script>

<style>
#variant-list-item{
	margin-bottom: 8px;
	border: 1px solid #32A3FF;
	border-radius: 8px;
	padding: 13px 16px;
	text-align: left;
	position: relative;
	overflow: hidden;
}

.variant-list-item--hidden {
	background-color: red;
}

.variant-list-item__remove{
	position: absolute;
	top: 0;
	background-color: #FF5656;
	width: 30%;
	height: 100%;
	border-radius: 0 10px 10px 0;
	left: 70%;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 15.5px;
	line-height: 23px;
	display: flex;
	justify-content: center;
	align-items: center;

	color: #FFFFFF;
	transition: 0.3s;
	
}

.variant-list-item__remove--hidden{
	left: 100%;
}


.variant-list-item__name{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	text-transform: capitalize;
	color: #444444;
	display: flex;
	height: 100%;
	align-items: center;
}

.variant-list-item__yield{
	display: flex;
	height: 100%;
	align-items: center;
}

.variant-list-item__yield__input{
	border-bottom: 1px solid #898989;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	max-width: 40px;
}

.variant-list-item__yield__input:focus-visible{
	outline: 0;
}

.variant-list-item__percent{
	position: relative;
	background: rgba(118, 118, 118, 0.2);
	border: 1px solid #4D5061;
	border-radius: 10px;
	font-weight: 400;
	font-size: 17px;
	line-height: 26px;
	padding-left: 10px;
	width: 54px;
}

.variant-list-item__percent__input{
	width: 30px;
	background: transparent;
	border: 0
}
.variant-list-item__percent__input:focus-visible{
	outline: 0;
}

.variant-list-item__percent__input::-webkit-outer-spin-button,
.variant-list-item__percent__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.variant-list-item__percent::after{
	content: '%';
	position: absolute;
	top: 1px;
	right: 4px;
}

.variant-list-item__percent::before{
	content: url('../../../assets/percent-input.svg');
	position: absolute;
	top: 5px;
	left: -7px;
}

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .variant-list-item__percent{
		margin: 10px 0;
	}

	.variant-list-item__remove{
		top: 50%;
		background-color: #FF5656;
		width: 100%;
		height: 50%;
		left: 0;
		border-radius: 0 0 10px 10px;
	}

	.variant-list-item__remove--hidden{
		left: 0;
		top: 100%;
	}
	
}


</style>