import { QueryObserver } from "@tanstack/vue-query";
import { growAreasFunctions } from '../functions'
import {queryClient} from '../../queryClient'

const observerQueryClient = queryClient

// Hooks wont work because mixing data() and setup() in components would
// add unneeded complexity

const getObserverInstance = (farm, queryParams) => {
	const observer = new QueryObserver(observerQueryClient, {
		queryKey : ['getGAs', farm, JSON.stringify(queryParams)],
		queryFn : () => growAreasFunctions.fetchGrowAreas(farm),
		retry: false,
		staleTime: 5 * 60 * 1000,
	})


	const setOptions = (newFarm, newQueryParams) => {
		observer.setOptions({
			queryKey : ['getGAs', newFarm, JSON.stringify(newQueryParams)],
			queryFn : () => growAreasFunctions.fetchGrowAreas(newFarm),
			retry: false,
			staleTime: 5 * 60 * 1000,
		})
	}

	return {observer, setOptions}
}


const cancelQueries = () => {
	observerQueryClient.cancelQueries();
}

export default {
	cancelQueries,
	getObserverInstance,
	observerQueryClient,
}