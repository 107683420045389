var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"creation-cycle-list-item"}},[_c('div',{staticClass:"creation-cycle-list-item__wrapper"},[_c('b-row',{class:{
		'creation-cycle-list-item-content' : true
		},attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"creation-cycle-list-item__name",attrs:{"cols":"3"}},[_c('input',{staticClass:"creation-cycle-list-item__name__input",on:{"input":_vm.debounceInput}})]),_c('b-col',{staticClass:"creation-cycle-list-item__info",attrs:{"cols":"7"}}),_c('b-col',{staticClass:"creation-cycle-list-item__stat",attrs:{"cols":"2"}},[_c('button',{style:({
					width : '20px',
					height : '20px',
					borderRadius : '20px',
					backgroundColor : _vm.selectedPlantingZone ? _vm.generateColorGZ(_vm.selectedPlantingZone.name).color : 'red',
					outline : '0',
					border : '0'
				}),on:{"click":() => _vm.showModal = true}})])],1)],1),_c('b-modal',{attrs:{"ok-only":"","no-close-on-backdrop":"","no-close-on-esc":"","id":"modal-1","title":"Select planting zone"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-select',{attrs:{"clearable":false,"label":"name","options":_vm.zones},model:{value:(_vm.selectedPlantingZone),callback:function ($$v) {_vm.selectedPlantingZone=$$v},expression:"selectedPlantingZone"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }