<template>
	<div id="cycle-variant-cc-list">
		<!--render all created variants-->
		<div
			v-for="(variant, index) in newCycleObject.cycleVariants"
			:key="index"
		>
			<VariantListItem
				:variant="{...variant, '_id' : index}"
				:deleteMode="false"
				:pendingVariantChanges="pendingVariantChanges"
				@pendingVariantChangesChange="handlePendingVariantChangesChange"
			/>
		</div>

		<!--select to create new variant-->
		<v-select
			id="cycle-variant-cc-list__select"
			:options="variants"
			:reduce="option => option.value"
			:components="{OpenIndicator}"
			placeholder="Select Variant"
			:clearable="false"
			v-model="currentSelectedVariant"
		></v-select>
	</div>
</template>

<script>
import VariantListItem from './VariantListItem.vue';
import OpenIndicator from './OpenIndicator.vue'

export default {
	name: "CycleVariantList",
	props : ['variants'],
	data(){
		return {
			currentSelectedVariant : null,
			OpenIndicator,
			pendingVariantChanges : {
					add : [],
					remove : [],
					update : [],
					cycle : null,
				},
		}
	},
	computed: {
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation
		},
		newCycleObject()
		{
			return this.$store.state.newCycleObject
		},
	},
	methods : {
		/**
		 * handlePendingVariantChangesChange - handles changes on handlePendingVariantChanges
		 * from list items
		 * @param {*} e 
		 */
		handlePendingVariantChangesChange(e)
		{
			this.pendingVariantChanges = e;
		}
	},
	components: {
		VariantListItem
	},
	watch:{
		/**
		 * currentSelectedVariant - everytime another variant gets selected(created), push the 
		 * new variant to pending changes
		 */
		currentSelectedVariant(){
			if (!this.currentSelectedVariant)
				return;
			this.newCycleObject.cycleVariants.push({
				name: this.currentSelectedVariant,
				percentage: 0,
				forecastedYieldPerUnit: 0,
				_id : this.newCycleObject.cycleVariants.length
			})
			this.currentSelectedVariant = null;
		},
		/**
		 * pendingVariantChanges - everytime pendingVariantChanges changes
		 * iterates through existing variants and apply changes
		 */
		pendingVariantChanges : {
			handler()
			{
				// loop through create changes

				// loop through update changes
				for (let index = 0; index < this.pendingVariantChanges.update.length; index++) {
					const elem = this.pendingVariantChanges.update[index]
					// find index of new variant using id
					const variantIndex = this.newCycleObject.cycleVariants?.findIndex((e) => e._id == elem._id);

					if (variantIndex > -1)
						this.newCycleObject.cycleVariants[variantIndex] = elem


				}
			},
			deep: true
		},
	}
}
</script>

<style>
#cycle-variant-cc-list{
	padding: 26px 0px;
}

#cycle-variant-cc-list__select .vs__dropdown-toggle{
	background: #FFFFFF;
	border: 1px solid #32A3FF;
	border-radius: 8px;
	padding: 13px 16px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 26px;
	/* identical to box height */

	text-transform: capitalize;

	color: #444444;
}
</style>