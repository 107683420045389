<template>
	<div id="cycle-actions">
		<div
			class="cycle-actions__variants-btn"
		>
			<div>
				<Pagination
					:currentPage="currentPage"
					:pageSize="pageSize"
					:totalElements="totalElements"
					@pageChange="handlePageChange"
				/>
			</div>
		</div>

		<b-row>
			<b-col cols="0" sm="7" class="cycle-actions__pagination-row">
				<Pagination
					:currentPage="currentPage"
					:pageSize="pageSize"
					:totalElements="totalElements"
					@pageChange="handlePageChange"
				/>
			</b-col>
			<b-col cols="12" sm="5">
				<div
					:style="{
						display : 'flex',
						justifyContent : 'end',
						gap : '5px'
					}"
				>
					<!-- delete / cancel add btn -->
					<button
						:disabled="!selectedCycle"
						@click="handleCycleDel"
						class="cycle-actions__variants-delete-btn">
							<i class="fas fa-trash"></i>
					</button>

					<!-- Add / save cycle btn -->
					<b-button
						pill
						variant="outline-info"
						size="sm"
						:disabled="newCycleCreation"
						@click="enableCreation"
					>
						<i class="fas fa-plus"></i> Add new cycle
					</b-button>
				</div>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import { cycleObserver } from '../../../query/plantingPlan/observers'
import Pagination from '../../pagination/Pagination.vue'

export default {
	name: "CycleListActions",
	props : {
		cycle : null,
		currentPage: Number,
		pageSize : Number,
		totalElements: Number,
	},
	components : {
		Pagination
	},
	computed:
	{
		selectedCycle()
		{
			return this.$store.state.selectedCycle
		},
		newCycleObject()
		{
			return this.$store.state.newCycleObject
		},
		newCycleCreation()
		{
			return this.$store.state.newCycleCreation
		},
		farm()
		{
			return this.$store.state.farm
		}
	},
	data(){
		return {
			cycleMutation: null, // cycle mutation instance
			cycleMutationUnsubscribe: () => {}, // cycle mutation unsub fn
			cycleMutationResult: null, // cycle mutation result
		}
	},
	methods : {
		/**
		 * handlePageChange - handle page change pagination
		 * @param {*} page 
		 */
		handlePageChange(page)
		{
			this.$emit('pageChange', page)
		},
		/**
		 * enableCreation - enable creation mode
		 */
		enableCreation()
		{
			this.$store.commit('setNewCycleCreation', true);
			this.$store.commit('setSelectedCycle', null);
		},
		/**
		 * handleCycleDel - handles cycle deletion
		 */
		handleCycleDel()
		{
            const task = () => new Promise((resolve, reject) => {
                this.cycleMutation.mutate(
					this.farm,
					null,
					this.selectedCycle._id,
					reject,
					resolve,
					false,
					true,
				);
            })

			task()
			.then(() => this.$emit('delCycle', this.selectedCycle._id))
			.catch((e) => {
					console.error(e)
			})
        }
	},
	/**
     * mounted - subscribes to cycleObserver, events and listen for fetch event completion
     * sets data for cycles and provides user feedback of any error occurs
     */
	mounted()
	{
		const mutationInstance = cycleObserver.getMutationInstance(this.farm)
		this.cycleMutation = mutationInstance
		this.cycleMutationUnsubscribe = mutationInstance.observer.subscribe((result)=>{
			this.cycleMutationResult = result
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
					title: 'Error',
					autoHideDelay: 3000,
					solid : true,
					variant: 'danger'
					})
					return;
			}
		})
	},
}
</script>

<style>
#cycle-actions{
	margin-top: 10px;
	margin-bottom: 10px;
}

.cycle-actions__variants-btn {
	display : none
}

.cycle-actions__variants-delete-btn {
	width: 31px;
	height: 31px;
	border-radius: 50%;
	background-color: #FF5656;
	border: none;
	color: #464646;
}

.cycle-actions__variants-delete-btn:hover {
	background-color: transparent;
	border: 1px solid #FF5656;
	color: #FF5656;
}

.cycle-actions__variants-delete-btn:disabled{
	background-color: white;
	color: #a6a6a6;
}

@media only screen and (max-width: 426px) {
  /* For mobile phones: */
  .cycle-actions__variants-btn {
	display : flex;
	justify-content: flex-end;
}
.cycle-actions__pagination-row {
	display: none;
}
}

</style>