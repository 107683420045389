<template>
	<div id="cycle-gamap">
		<div class="cycle-gamap-items-container scroll">
			<div class="cycle-gamap-items-container-items"
			v-for="ga in growAreas" :key="ga._id"
			:style="generateDynamicStyle(ga)"
			@click="() => handleItemClick(ga)"
			>
				<!--DEV change this to gaID ?-->
				{{ ga.growarea }}
			</div>
		</div>
	</div>
</template>

<script>
import generateColorForGrowingZone from '../scripts/generateColorForGrowingZone';

export default {
	name : "CycleGAMap",
	props : {
		growAreas : {type : Array},
		zones : {type : Array},
		pendingChanges : {type : Object}
	},
	computed : {
		selectedZone(){
			return this.$store.state.selectedZone;
		}
	},
	methods : {
		// handles item click
		handleItemClick(ga){
			// check if no selected zone
			if (!this.selectedZone) return ;
			
			// check if add or remove has ga already
			const foundGAInAdd = this.pendingChanges.add.findIndex((e) => e._id == ga._id)
			const foundGAInRm = this.pendingChanges.remove.findIndex((e) => e._id == ga._id)

			// remove it from them
			if (foundGAInAdd >= 0)
			{
				this.$emit('newAdd', this.pendingChanges.add.filter(e => e._id != ga._id))
				return ;
			}
			if (foundGAInRm >= 0)
			{
				this.$emit('newRemove', this.pendingChanges.remove.filter(e => e._id != ga._id))
				return ;
			}

			// check if current ga is included in selectedzone
			const foundGAInZone = this.selectedZone.growareas.findIndex((e) => e._id == ga._id)

			// if yes, add to remove
			if (foundGAInZone >= 0)
			{
				this.$emit('newRemove', [...this.pendingChanges.remove, ga])
				return ;
			}
			// if no, add to add
			// find zone in zones prop excluding curr zone
			const foundZone = this.zones.find((zone) => {
				if (zone._id == this.selectedZone._id) return false;

				// loop through GA
				const foundGA = zone.growareas.findIndex((e) => e._id == ga._id)
				return foundGA >= 0;
			})
			if (foundZone) return;
			this.$emit('newAdd', [...this.pendingChanges.add, ga])
			return ;
		},
		// generat style classes for items
		generateDynamicStyle(ga) {
			const res = {};
			const selectedZone = this.selectedZone;
			
			if (selectedZone)
			{
				// if ga is in seleted zone, get colors
				const foundGA = selectedZone.growareas.findIndex((e) => e._id == ga._id)
				if (foundGA < 0)
				{
					// if not, find in other zones.
					// find zone in zones prop excluding curr zone
					const foundZone = this.zones.find((zone) => {
						if (zone._id == selectedZone._id) return false;

						// loop through GA
						const foundGA = zone.growareas.findIndex((e) => e._id == ga._id)
						return foundGA >= 0;
					})

					// find ga in pendingchanges
					const foundPendingZoneAdd = this.pendingChanges.add.find((e) => e._id == ga._id)

					// if found, grey, if not or ga is pending remove and not pending add, avail
					if (foundZone)
					{
						res['backgroundColor'] = '#BABABA'
						res['border'] = '2px solid #9C9C9C'
						res['color'] = '#D7D7D7'
						return res;
					}
					else if (!foundPendingZoneAdd){
						res['backgroundColor'] = 'white'
						res['border'] = '2px solid #32A3FF'
						res['color'] = '#7C7C7C'
						return res;
					}
				}
				// there is a selected zone
				const foundPendingZoneRm = this.pendingChanges.remove.find((e) => e._id == ga._id)
				if (foundPendingZoneRm)
				{
					res['backgroundColor'] = 'white'
					res['border'] = '2px solid #32A3FF'
					res['color'] = '#7C7C7C'
					return res;
				}
				
				const color = generateColorForGrowingZone(selectedZone.name);
				res['backgroundColor'] = color.color
				res['border'] = '2px solid ' + color.borderColor
				res['color'] = 'white'
				return res;
			}

			// there is no selected zone
			// find zone the ga belongs in and get color
			// if ga is not in any zone, grey
			else {
				const foundZone = this.zones.find((zone) => {
					// loop through GA
					const foundGA = zone.growareas.findIndex((e) => e._id == ga._id)
					return foundGA >= 0;
				})
				if (foundZone){
					const color = generateColorForGrowingZone(foundZone.name);
					res['backgroundColor'] = color.color
					res['border'] = '2px solid ' + color.borderColor
					res['color'] = 'white'
					return res;
				}
				res['backgroundColor'] = '#BABABA'
				res['border'] = '2px solid #9C9C9C'
				res['color'] = '#D7D7D7'
				return res;
			}
		}
	}
}
</script>

<style>
.cycle-gamap-items-container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	column-gap: 15px;
}

.cycle-gamap-items-container-items {
	width: 60px;
	height: 26px;
	border-radius: 4px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 17.8711px;
	line-height: 27px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
}
</style>