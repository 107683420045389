<template>
	<div id="cycle-zone-display">
		<!-- dialog to save changes-->
		<div
		:class="{
			'cycle-zone-display__save-dialog--hidden' : true,
			'cycle-zone-display__save-dialog' : pendingChanges.add.length > 0 || pendingChanges.remove.length > 0,

		}"
		>
		<b-row :style="{minHeight : '53px'}">

			<!--prompt text-->
			<b-col
			cols="12"
			sm="7"
			:style="{
				display : 'flex',
				justifyContent : 'center',
				alignItems: 'center',
				fontWeight: '400',
				fontSize: '16px',
				}">
				Changes found
			</b-col>

			<!--action buttons-->
			<b-col
			cols="12"
			sm="5"
			:style="{
				display : 'flex',
				justifyContent : 'center',
				alignItems: 'center',
				gap : '10px'
				}">

				<b-spinner small v-if="zoneMutationResult?.isLoading"></b-spinner>
				<div v-else>
					<b-button
					size="sm"
					variant="info"
					@click="handleSaveZoneChanges"
					>
						Save <i class="fas fa-check-circle"></i>
					</b-button>
					<b-button
					size="sm"
					variant="secondary"
					@click="handleDiscardZoneChanges"
					>
						Discard <i class="fas fa-trash"></i>
					</b-button>
				</div>
			</b-col>
		</b-row>
		</div>

		<!-- top bar-->
		<div class="cycle-zone-display__toptab">
			<b-row no-gutters>
				<!--stacked icons-->
				<b-col cols="5"
				id = "cycle-zone-display__toptab__stackedicons"
				class="
				cycle-zone-display__toptab__stackedicons
				scroll
				">
					<div v-for="(zone, index) in zones" :key="zone._id">
						<div class="cycle-zone-display__toptab__stackedicons__icon__container">
							<div
							@click="()=>selectZone(zone)"
							:class="{
								'cycle-zone-display__toptab__stackedicons__icon' : true,
								'cycle-zone-display__toptab__stackedicons__icon--selected' : zone._id == selectedZone?._id 
							}"
							:style="{
								left : `-${isFocusedOnTab ? 0 : index * 20}px`,
								backgroundColor : generateColorForGrowingZone(zone.name).color
							}">
								{{ zone.name.charAt(0) }}
							</div>
						</div>
					</div>
				</b-col>

				<!-- capacity display  -->
				<b-col cols="6">
					<CapacityDisplay
					:pendingChanges="pendingChanges"
					:growAreas="growAreas"
					:selectedZone="selectedZone"
					/>
				</b-col>

				<!--menu button-->
				<b-col cols="1">
					<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<i class="fas fa-ellipsis-v"
							:style="{
								color : '#D9D9D9'
							}"
							></i>
						</template>
						<b-dropdown-item @click="() => showCycleCreateModal = true">Add new zone</b-dropdown-item>
						<b-dropdown-item v-if="selectedZone"  @click="() => showCycleDeleteModal = true">Delete current zone</b-dropdown-item>
					</b-dropdown>
				</b-col>
			</b-row>
		</div>

		<!-- growarea map -->
		<CycleGAMap
		:growAreas="growAreas"
		:zones="zones"
		:pendingChanges="pendingChanges"
		@newAdd="(data) => this.handlePendingChangesChange('add', data)"
		@newRemove="(data) => this.handlePendingChangesChange('remove', data)"
		/>

		<!-- zone creation modal -->
		<CycleZoneCreateModal
		:showModal="showCycleCreateModal"
		@modalClose="() => showCycleCreateModal = false"
		:growAreas="growAreas"
		:zones="zones"
		/>

		<!-- zone deleteion modal -->
		<CycleZoneDeleteModal
		:showModal="showCycleDeleteModal"
		@modalClose="() => showCycleDeleteModal = false"
		:zone-to-del="selectedZone"
		@zoneDeleted="(id) => $emit('zoneDelete', id)"
		/>
	</div>
</template>

<script>
import { cycleZoneObserver } from '../../../query/plantingPlan/observers';
import generateColorForGrowingZone from '../scripts/generateColorForGrowingZone';
import CapacityDisplay from './CapacityDisplay.vue';
import CycleGAMap from './CycleGAMap.vue';
import CycleZoneCreateModal from './CycleZoneCreateModal.vue';
import CycleZoneDeleteModal from './CycleZoneDeleteModal.vue';

export default {
	name : "CycleZoneDisplay",
	props : {
		zones : {type: Array}, // planting plan zones
		growAreas : {type: Array},
	},
	computed : {
		selectedZone(){
			return this.$store.state.selectedZone;
		},
		farm()
        {
            return this.$store.state.farm;
        },

	},
	data(){
		return {
			isFocusedOnTab : false, // tab icons focus state
			gaLoading: false, //growarea loading state
			pendingChanges : {
				add : [],
				remove : [],
				// zone : this.selectedZone,
				zone : this.$store.state.selectedZone,
			}, // pending made by user
			isSaving : false, // save loading state
			zoneMutation: null, // zone mutation instance
			zoneMutationUnsubscribe: () => {}, // zone mutation unsub fn
			zoneMutationResult: null, // zone mutation result
			showCycleCreateModal : false, // show cyclecreate modal
			showCycleDeleteModal : false, // show cycledelete modal
		}
	},
	methods : {
		/**
		 * saveToBackend - save a mutation of a zone to the backend
		 * @param {*} body 
		 * @param {*} id 
		 */
		saveToBackend(body, id)
		{
			return new Promise((resolve, reject) => {
				this.zoneMutation.mutate(
					this.farm,
					body,
					id,
					'update',
					reject,
					resolve,
				)
			})
			
		},
		/**
		 * handleSaveZoneChanges - handles the action where a zone gets updated, both ui changes
		 * and the backend call
		 */
		handleSaveZoneChanges(){
			let currGAsClone = [...this.selectedZone.growareas];
			const zoneClone = { ...this.selectedZone };

			// add growareas into selected zone
			this.pendingChanges.add.forEach(addChange => {
				currGAsClone.push(addChange)
			});

			// remove growares from selected zone
			currGAsClone = currGAsClone.filter((e) => {
				// find elemnt in remove set
				const i = this.pendingChanges.remove.findIndex((chg) => e._id == chg._id)
				if (i >= 0) return false;
				return true;
			})

			zoneClone.growareas = currGAsClone;
			
			// save to backend
			this.saveToBackend(zoneClone, this.selectedZone._id)
			.then((data) => {

				if (data.growareas)
				{
					this.$bvToast.toast(`Saved Cycle Zone changes`, {
						title: 'Success',
						autoHideDelay: 3000,
						solid : true,
						variant: 'success'
					})

					this.selectedZone.growareas = data.growareas;
					this.$emit('zoneUpdate', zoneClone)
					this.handleDiscardZoneChanges()
					this.$store.commit('setSelectedZone', zoneClone)
					return ;
				}
				
				alert('rendering growareas error')

			})
			.catch((error) => {
				console.error(error)
			})
		},
		/**
		 * handleDiscardZoneChanges - handles the action where a zone change gets discarded
		 */
		handleDiscardZoneChanges()
		{
			this.pendingChanges = {
				add : [],
				remove : [],
				zone : this.selectedZone,
			}
		},
		generateColorForGrowingZone,

		/**
		 * handleFocusIconTab - determine if an element is a descendant of cycle-zone-display__toptab__stackedicons
		 * and sets isFocusedOnTab accordingly
		 * @param {*} e 
		 */
		handleFocusIconTab(e)
		{
			if (this.isDescendant(e.target, "cycle-zone-display__toptab__stackedicons"))
				this.isFocusedOnTab = true;
			else
				this.isFocusedOnTab = false
		},
		/**
		 * selectZone - handles state manaagement for cycle zone selection
		 * @param {*} zone 
		 */
		selectZone(zone){
			if (zone && zone._id == this.selectedZone?._id)
				this.$store.commit('setSelectedZone', null);
			else
				this.$store.commit('setSelectedZone', zone)
		},
		/**
		 * isDescendant - determines if el is a descendant of parentId
		 * @param {*} el 
		 * @param {*} parentId 
		 */
		isDescendant(el, parentId)
		{
			if (el.id === parentId) {//is this the element itself?
				return true
			}

			el = el.parentNode
			while (el) {
				if (el.id == parentId) {
					return true
				}
				el = el.parentNode;
			}
			
			return false
		},
		/**
		 * handlePendingChangesChange - state management for handling the change for 'pending changes' object. 
		 * @param {*} field 
		 * @param {*} data 
		 */
		handlePendingChangesChange(field, data)
		{
			this.pendingChanges[field] = data
		}
	},
	watch : {
		selectedZone(){
			this.pendingChanges = {
				add : [],
				remove : [],
				zone : this.selectedZone,
			}
		},
	},
	/**
     * mounted - subscribes to cycleZonesObserver events and listen for fetch event completion
     * sets data for zones and provides user feedback of any error occurs
     */
	mounted()
	{
		window.addEventListener('click', this.handleFocusIconTab)

		const mutationInstance = cycleZoneObserver.getMutationInstance(this.farm)
		this.zoneMutation = mutationInstance
		this.zoneMutationUnsubscribe = mutationInstance.observer.subscribe((result)=>{
			this.zoneMutationResult = result
			if (result.isError)
			{
				this.$bvToast.toast(result.error?.response?.data.error, {
					title: 'Error',
					autoHideDelay: 3000,
					solid : true,
					variant: 'danger'
					})
				return ;
			}
			
		})
	},
	beforeDestroy()	
	{
		window.removeEventListener('click', this.handleFocusIconTab)
		this.zoneMutationUnsubscribe()
	},
	components : {
		CycleGAMap,
		CycleZoneCreateModal,
		CycleZoneDeleteModal,
		CapacityDisplay,
	}
}
</script>

<style>
#cycle-zone-display{
	position: relative;
	border-top: 1px solid grey;
}

.cycle-zone-display__toptab{
	padding: 20px 0;
}

.cycle-zone-display__save-dialog--hidden{
	position: absolute;
	opacity: 0.5;
	width: 100%;
	min-height: 53px;
	top : 0;
	opacity: 0;
	background: #FFFFFF;
	/* Drop Shadow */

	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	transition: 0.5s;
}

.cycle-zone-display__save-dialog{
	top: -73px;
	opacity: 1;
	transition: 0.5s;
}

.cycle-zone-display__toptab__stackedicons {
	display: flex;
	gap: 8px;
	overflow: auto;
}

.cycle-zone-display__toptab__stackedicons__icon__container {
	position: relative;
	width: 34px;
	height: 34px;
}


.cycle-zone-display__toptab__stackedicons__icon {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	position: absolute;
	color: white;
	transition: left 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16.2112px;
	line-height: 24px;
	text-transform: uppercase;
}

.cycle-zone-display__toptab__stackedicons__icon--selected{
	border: 2px solid #32A3FF;
}


@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .cycle-zone-display__save-dialog {
	padding: 10px 0;
  }
}

</style>